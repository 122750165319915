
import { DateAsString, DocumentAnswerV2Dto, htmlDto, IdDto, ShoeboxItemListRequestDto, ShoeboxItemRequestDto, ShoeboxItemResponseDto, ShoeboxItemStatusType } from 'src/backend';
import { AppUserDTO2Extended } from 'src/types/user';
import { getHumanFileSize } from 'src/utils/file/get-human-file-size';
import { extendShoeboxItem } from 'src/utils/shoebox/extend-shoebox-item';

import { baseApi } from './baseApi';

export interface ShoeboxItemResponseDtoExtended extends ShoeboxItemResponseDto {
    lastModifiedDateFormatted: string;
    lastModifiedByDisplayName: string;
    lastModifiedByUser: AppUserDTO2Extended;
    lastModifiedDate: DateAsString;
    size: DocumentAnswerV2Dto['size'];
    sizeFormatted: ReturnType<typeof getHumanFileSize>;
    whenDeletedFormatted: string;
    lastModifiedTimeStamp: number;
    uploadedBy: AppUserDTO2Extended;
}

export const lenderShoeBoxApi = baseApi.enhanceEndpoints({ addTagTypes: ['ShoeboxItemResponseDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getMyShoeBoxItems: build.query<ShoeboxItemResponseDtoExtended[], void>({
            query: () => ({
                url: `/v2/shoebox/lender`,
                method: 'GET',
            }),
            providesTags: ['ShoeboxItemResponseDto'],
            transformResponse: (response: ShoeboxItemResponseDto[]) => response.map(extendShoeboxItem),
        }),
        createShoeBoxItem: build.mutation<ShoeboxItemResponseDto, ShoeboxItemRequestDto>({
            query: (data) => ({
                url: `/v2/shoebox/item`,
                method: 'POST',
                data,
            }),
            invalidatesTags: ['ShoeboxItemResponseDto'],
        }),
        getLoanShoeBoxItems: build.query<ShoeboxItemResponseDtoExtended[], {
            loan: string,
            status?: ShoeboxItemStatusType
        }>({
            query: (data) => ({
                url: `/v2/shoebox/loan`,
                method: 'GET',
                data
            }),
            providesTags: ['ShoeboxItemResponseDto'],
            transformResponse: (response: ShoeboxItemResponseDto[]) => {
                // sort alphabetically
                return response.map(extendShoeboxItem).sort((a, b) => a.title?.localeCompare(b.title, 'en', { numeric: true }));
            },
        }),
        findShoeBoxItem: build.query<ShoeboxItemResponseDto, IdDto>({
            query: (data) => ({
                url: `/v2/shoebox/item/${data.id}`,
                method: 'GET',
            }),
            providesTags: ['ShoeboxItemResponseDto'],
        }),
        updateShoeBoxItem: build.mutation<ShoeboxItemResponseDto, ShoeboxItemRequestDto>({
            query: (data) => ({
                url: `/v2/shoebox/item`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: ['ShoeboxItemResponseDto'],
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    lenderShoeBoxApi.util.updateQueryData('getMyShoeBoxItems', null, (draft) => {
                        // remove the item from the list
                        const index = draft.findIndex((item) => item.id === id)
                        if (index !== -1) {
                            draft.splice(index, 1)
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
        }),
        generateShoeboxItemsListBody: build.mutation<string, ShoeboxItemListRequestDto>({
            query: (data) => ({
                url: `/v1/messages/generateShoeboxItemsListBody`,
                method: 'POST',
                data,
            }),
            transformResponse: (response: htmlDto) => response.html
        }),
        markShoeboxItemForDelete: build.mutation<void, IdDto>({
            query: (data) => ({
                url: `/v2/shoebox/markitem`,
                method: 'DELETE',
                data,
            }),
            invalidatesTags: ['ShoeboxItemResponseDto'],
        }),
        unMarkShoeboxItemForDelete: build.mutation<void, IdDto>({
            query: (data) => ({
                url: `/v2/shoebox/unmarkitem`,
                method: 'POST',
                data,
            }),
            invalidatesTags: ['ShoeboxItemResponseDto'],
        }),
        deleteShoeBoxItem: build.mutation<void, IdDto>({
            query: (data) => ({
                url: `/v2/shoebox/item`,
                method: 'DELETE',
                data,
            }),
            invalidatesTags: ['ShoeboxItemResponseDto'],
        }),
    }),
    overrideExisting: true,
})

export const {
    useMarkShoeboxItemForDeleteMutation,
    useUnMarkShoeboxItemForDeleteMutation,
    useGenerateShoeboxItemsListBodyMutation,
    useFindShoeBoxItemQuery,
    useGetLoanShoeBoxItemsQuery,
    useGetMyShoeBoxItemsQuery,
    useCreateShoeBoxItemMutation,
    useUpdateShoeBoxItemMutation,
    useDeleteShoeBoxItemMutation,
} = lenderShoeBoxApi;



