import { LoanGroupBy } from "src/components/loans/loan-explorer/loan-explorer-loans/loan-explorer-loans-filters/loan-explorer-loans-filters.types";
import { FormElementGroupBy } from "src/components/loans/tabs/overview/v2/loan-overview-needs-list/loan-overview-needs-list.group/loan-overview-needs-list.group.types";

const I18NStrings = {
    "en": {
        'max255': 'Must be at most 255 characters',
        "required": "Required",
        "assignQuestion": "Assign",
        "fillAndSign": "Fill & Sign",
        "assignSignee": "Assign Signee",
        "shoeBox": "Categorize",
        "accept": "Accept",
        "upload": "Upload",
        "acceptThisDocument": "Accept this document",
        "acceptedDocuments": "Accepted Documents",
        "approvedBy": "Approved by",
        "acceptedBy": "Accepted by",
        "acceptSelected": 'Accept Selected',
        "documentsAccepted": "Documents Accepted",
        "documentExpired": "Document Expired",
        "loanProgress": "Loan Progress",
        "uploadedDocuments": "Uploaded Documents",
        "accepted": "Accepted",
        "approved": "Approved",
        "fillForm": "Fill Form",
        "answered": "Answered",
        "submitted": "Submitted",
        "uploadCanceled": "Upload Canceled",
    }
};

export const getString = (text: keyof typeof I18NStrings['en']): string => {
    return I18NStrings['en'][text];
}

export const NeedsListGroupByLabel: Record<FormElementGroupBy, string> = {
    'TYPE': 'Type',
    'STATUS': 'Status',
    'MODIFIED': 'Modified',
    'ASSIGNEE': 'Assignee',
    'LOAN_STAGE': 'Loan Stage',
    'ENTITY': 'Entity',
}

export const LoansListGroupByLabel: Record<LoanGroupBy, string> = {
    'MODIFIED': 'Modified',
    'STAGE': 'Loan Stage',
    'TYPE': 'Loan Type',
};