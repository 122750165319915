
import type { AnonCreateSessionForUserDto, AnonSessionDto, AppUserAvatarDto, AppUserAzureEmailDto, AppUserDTO2, AppUserPreferencesDTO, FePreferenceEntityResponseDto, IndividualRequestDto, IndividualResponseDto, NewCreditConsentDTO, OnboardDTO, RoleLenderGroupLevel, SelectRoleDto } from 'src/backend';
import type { OnboardDTOExtended } from 'src/types/user';
import { extendAppUserDTO } from 'src/utils/user/extend-app-user-dto';

import { baseApi } from './baseApi';


const extendOnboardDTO = (onboard: OnboardDTO): OnboardDTOExtended => {
    return {
        ...onboard,
        ...onboard.user && {
            user: extendAppUserDTO(onboard.user)
        }
    }
}

export const userApi = baseApi.enhanceEndpoints({ addTagTypes: ['MessageUnreadDto', 'MessageThreadDto', 'MessageSearchPageDto', 'FormElementsV2ResponseDto', 'BasicLoanDto', 'MessageDto', 'MessageThreadUnreadCount', 'ConsolidatedTasksDto', 'FePreferenceEntityResponseDto', 'OnboardDTO', 'AppUserPreferencesDTO', 'AppUserDTO2'] }).injectEndpoints({
    endpoints: (build) => ({
        getLoggedInUser: build.query<OnboardDTOExtended, void>({
            query: () => ({
                url: '/v2/users/loggedUser',
                method: 'GET',
            }),
            providesTags: (response) => {
                return typeof response?.user !== 'undefined'
                    ? [
                        { type: 'AppUserDTO2', id: response.user.id },
                        { type: 'OnboardDTO', id: response.user.id },
                        { type: 'OnboardDTO', id: 'LIST' }
                    ]
                    : []
            },
            transformResponse: extendOnboardDTO,
        }),
        addSoftCreditPull: build.mutation<OnboardDTO, { userId: string, payload: NewCreditConsentDTO }>({
            query: (data) => ({
                url: `/v2/users/${data.userId}/creditConsent`,
                method: 'POST',
                data: data.payload,
            }),
            invalidatesTags: (_, __, args) => [{ type: 'AppUserDTO2', id: args.userId }],
            transformResponse: extendOnboardDTO
        }),
        setUserAvatar: build.mutation<AppUserDTO2, AppUserAvatarDto>({
            query: (data) => ({
                url: '/v2/users/avatar',
                method: 'POST',
                data,
            }),
            invalidatesTags: (_, __, args) => [{ type: 'AppUserDTO2', id: args.userId }]
        }),
        generateSessionForUser: build.mutation<AnonSessionDto, AnonCreateSessionForUserDto>({
            query: (data) => ({
                url: '/v1/auth/anonSession',
                method: 'POST',
                data,
            }),
        }),
        getGenerateSessionForUser: build.query<AnonSessionDto, AnonCreateSessionForUserDto>({
            query: (data) => ({
                url: '/v1/auth/anonSession',
                method: 'POST',
                data,
            }),
        }),
        getKeyPreference: build.query<any, { key: string }>({
            query: (data) => ({
                url: `/v1/fepreference/${data.key}`,
                method: 'GET',
            }),
            transformResponse: (response: FePreferenceEntityResponseDto) => {

                if (typeof response.value === 'undefined' || response.value === null) {
                    return null
                }
                return JSON.parse(decodeURIComponent(response.value))
            },
            providesTags: (_, __, { key }) => [{ type: 'FePreferenceEntityResponseDto', id: key }],
        }),
        setKeyPreference: build.mutation<any, { key: string, value: any }>({
            query: (data) => ({
                url: `/v1/fepreference/${data.key}`,
                method: 'POST',
                data: JSON.stringify(data.value),
            }),
            async onQueryStarted({ key, value }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    userApi.util.updateQueryData('getKeyPreference', { key }, (draft) => {
                        if (draft !== null && typeof draft !== 'undefined') {
                            if (typeof value === 'string') {
                                Object.assign(draft, { value })
                            } else {
                                Object.assign(draft, value)
                            }
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()

                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
            invalidatesTags: (_, __, { key }) => [{ type: 'FePreferenceEntityResponseDto', id: key }],
        }),
        getUserSettings: build.query<AppUserPreferencesDTO, void>({
            query: () => ({
                url: '/v1/auth/userSettings',
                method: 'GET',
            }),
            providesTags: ['AppUserPreferencesDTO'],
        }),
        updateUserSettings: build.mutation<AppUserPreferencesDTO, Partial<AppUserPreferencesDTO>>({
            query: (data) => ({
                url: '/v1/auth/userSettings',
                method: 'PUT',
                data,
            }),
            // optimistic update
            async onQueryStarted(patch, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    userApi.util.updateQueryData('getUserSettings', undefined, (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                    dispatch(userApi.util.invalidateTags(['AppUserPreferencesDTO']))
                }
            },
            // invalidate and refetch
            invalidatesTags: ['AppUserPreferencesDTO'],
        }),
        deleteUserAvatar: build.mutation<void, AppUserAvatarDto>({
            query: (data) => ({
                url: '/v2/users/avatar',
                method: "DELETE",
                data
            }),
            invalidatesTags: (_, __, args) => [{ type: 'AppUserDTO2', id: args.userId }]
        }),
        updateUserAvatar: build.mutation<AppUserDTO2, AppUserAvatarDto>({
            query: (data) => ({
                url: '/v2/users/avatar',
                method: "PUT",
                data
            }),
            invalidatesTags: (_, __, args) => [{ type: 'AppUserDTO2', id: args.userId }]
        }),
        requestExtendSessionForUser: build.mutation<AnonSessionDto, { id: string }>({
            query: (data) => ({
                url: `/v1/auth/anonSession/${data.id}/extend`,
                method: 'POST',
                data,
            }),

        }),
        updateAzureEmail: build.mutation<AppUserDTO2, AppUserAzureEmailDto>({
            query: (data) => ({
                url: `/v2/users/azureEmail`,
                method: 'PUT',
                data,
            }),
            // invalidatesTags: (_, __, args) => [{ type: 'AppUserDTO2', id: args.userId }]
        }),
        getListPossibleRoles: build.query<SelectRoleDto[], void>({
            query: () => ({
                url: '/v1/auth/listPossibleGroupRoles',
                method: 'GET',
            }),
        }),
        setLoggedRole: build.mutation<AppUserDTO2, { roleLevel: RoleLenderGroupLevel, setDefault?: boolean }>({
            query: (data) => ({
                url: `/v1/auth/setLoggedRole/${data.roleLevel}?setDefault=${data.setDefault ?? ''}`,
                method: 'PUT',
            }),
            invalidatesTags: (_, __, args) => [
                { type: 'AppUserDTO2', id: 'LIST' },
                { type: 'OnboardDTO', id: 'LIST' },
                { type: 'BasicLoanDto', id: 'LIST' },
                { type: 'ConsolidatedTasksDto', id: 'LIST' },
                { type: 'MessageDto', id: 'LIST' },
                { type: 'MessageThreadDto', id: 'LIST' },
                { type: 'MessageSearchPageDto', id: 'LIST' },
                { type: 'FormElementsV2ResponseDto', id: 'LIST' },
                'MessageUnreadDto',
                'MessageThreadUnreadCount'
            ]
        }),
        resetLoggedRole: build.mutation<AppUserDTO2, void>({
            query: () => ({
                url: '/v1/auth/resetLoggedRole',
                method: 'PUT',
            }),
            invalidatesTags: [
                { type: 'AppUserDTO2', id: 'LIST' },
                { type: 'OnboardDTO', id: 'LIST' },
            ]
        }),
        searchUsers: build.query<AppUserDTO2[], { slice: string }>({
            query: (data) => ({
                url: `/v2/users/search`,
                method: 'GET',
                data
            }),
            providesTags: (response) => response?.map(({ id }) => ({ type: 'AppUserDTO2', id })),
        }),
        postIndividualInformation: build.mutation<AppUserDTO2, { entityId: string, payload: Partial<IndividualRequestDto> }>({
            query: (data) => ({
                url: `/v2/information/individual/${data.entityId}`,
                method: 'POST',
                data: data.payload,
            }),
            invalidatesTags: (_, __, args) => [{ type: 'AppUserDTO2', id: 'LIST' }, { type: 'OnboardDTO', id: 'LIST' }, { type: 'ConsolidatedTasksDto', id: 'LIST' }],
        }),
        getIndividualFromUser: build.query<IndividualResponseDto, { userId: string }>({
            query: (data) => ({
                url: `/v2/entities/individualFromUser/${data.userId}`,
                method: 'GET',
            }),
            providesTags: (response) => [{ type: 'AppUserDTO2', id: response.id }],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetIndividualFromUserQuery,
    usePostIndividualInformationMutation,
    useLazyGetIndividualFromUserQuery,
    useResetLoggedRoleMutation,
    useSearchUsersQuery,
    useGetListPossibleRolesQuery,
    useSetLoggedRoleMutation,
    useSetKeyPreferenceMutation,
    useGetKeyPreferenceQuery,
    useUpdateAzureEmailMutation,
    useAddSoftCreditPullMutation,
    useGetLoggedInUserQuery,
    useLazyGetLoggedInUserQuery,
    useGetUserSettingsQuery,
    useUpdateUserSettingsMutation,
    useGenerateSessionForUserMutation,
    useSetUserAvatarMutation,
    useGetGenerateSessionForUserQuery,
    useDeleteUserAvatarMutation,
    useUpdateUserAvatarMutation,
    useRequestExtendSessionForUserMutation,
    useLazyGetKeyPreferenceQuery,
} = userApi;



