import { AnonConfirmCodeRequestDto, AnonRequestDto, AnonSessionDto, AnonSubmitDto, AnonVerifyEmailRequestDto, AppUserDTO2, AppUserForgotPasswordRequestDto, BasicLoanDto, Document, PdfKeyDto, SecurityResponseDto, Upload, VerifyConfirmationCodeDto } from 'src/backend';
import { guessFileProviderType } from 'src/utils/document/guess-provider-type';

import { baseApi } from './baseApi';

export const anonymousApi = baseApi.enhanceEndpoints({ addTagTypes: ['Document', 'OnboardDTO'] }).injectEndpoints({
    endpoints: (build) => ({
        sendAnonymousVerificationCode: build.mutation<{ success: boolean }, AnonVerifyEmailRequestDto>({
            query: (data) => ({
                url: '/v1/noauth/anonSession/verifyEmail',
                method: 'POST',
                data,
            }),
            transformResponse: (response: SecurityResponseDto) => ({ success: response.success }),
        }),
        verifyAnonymousCode: build.mutation<{ session: string, success: boolean }, AnonConfirmCodeRequestDto>({
            query: (data) => ({
                url: '/v1/noauth/anonSession/verifyCode',
                method: 'POST',
                data,
            }),
            transformResponse: (response: SecurityResponseDto) => ({ session: response.session, success: response.success }),
        }),
        getLenderDocumentWithDownloadUrl: build.query<Document, { lenderUniqueCode: string, documentId: string }>({
            query: (data) => ({
                url: `/v1/noauth/anonSession/lenderDoc/${data.lenderUniqueCode}/${data.documentId}`,
                method: 'GET',
            }),
            providesTags: (_result, _error, { documentId }) => [{ type: 'Document', id: documentId }],
        }),
        getAnonymousUploadUrl: build.query<Upload, { session: string, name: string }>({
            query: (data) => {
                const providerType = guessFileProviderType(data.name);
                return ({
                    url: `/v1/noauth/anonSession/${data.session}/getUploadUrl`,
                    method: 'GET',
                    data: {
                        name: data.name,
                        iProviderType: providerType,
                    },
                })
            },
        }),
        getAnonymousLoans: build.query<BasicLoanDto[], { session: string }>({
            query: (data) => ({
                url: `/v1/noauth/anonSession/${data.session}/loans`,
                method: 'GET',
            }),
        }),
        postAnonymousUploadUrl: build.mutation<Document, { session: string, iUpload: Upload }>({
            query: (data) => ({
                url: `/v1/noauth/anonSession/${data.session}/createDocument`,
                method: 'POST',
                data: data.iUpload,
            })

        }),
        editAnonymousDocument: build.mutation<AnonSessionDto, { session: string, payload: AnonRequestDto }>({
            query: (data) => ({
                url: `/v1/noauth/anonSession/${data.session}/editDocument`,
                method: 'POST',
                data: data.payload,
            }),
            invalidatesTags: (_result, _error, { payload }) => [{ type: 'Document', id: payload.documentId }], // invalidates the tag with the session id
        }),
        submitAnonymousDocuments: build.mutation<AnonSessionDto, { session: string, payload: AnonSubmitDto }>({
            query: (data) => ({
                url: `/v1/noauth/anonSession/${data.session}/submit`,
                method: 'POST',
                data: data.payload,
            })

        }),
        getAnonymousPdfKey: build.query<string, { session: string }>({
            query: (data) => ({
                url: `/v1/noauth/anonSession/${data.session}/pdfKey`,
                method: 'GET',
            }),
            transformResponse: (response: PdfKeyDto) => response.pdfKey,
        }),
        getAnonymousDocumentWithDownloadUrl: build.query<Document, { session: string, documentId: string }>({
            query: (data) => ({
                url: `/v1/noauth/anonSession/${data.session}/documentWithDownloadUrl/${data.documentId}`,
                method: 'GET',
            }),
            providesTags: (_result, _error, { documentId }) => [{ type: 'Document', id: documentId }],
        }),
        getSession: build.query<AnonSessionDto, { id: string }>({
            query: (data) => ({
                url: `/v1/noauth/anonSession/${data.id}`,
                method: 'GET',
            }),
        }),
        requestSessionExtension: build.mutation<AnonSessionDto, { id: string, payload: AnonVerifyEmailRequestDto }>({
            query: (data) => ({
                url: `/v1/noauth/anonSession/${data.id}/requestExtension`,
                method: 'POST',
                data: data.payload,
            }),
        }),
        verifySessionExtension: build.mutation<SecurityResponseDto, { id: string, payload: AnonConfirmCodeRequestDto }>({
            query: (data) => ({
                url: `/v1/noauth/anonSession/${data.id}/verifyExtension`,
                method: 'POST',
                data: data.payload,
            }),
        }),
        getAnonymousLoggedUser: build.query<AppUserDTO2, { id: string }>({
            query: (data) => ({
                url: `/v1/noauth/anonSession/${data.id}/loggedUser`,
                method: 'GET',
            }),
        }),
        verifyConfirmationCode: build.mutation<VerifyConfirmationCodeDto, AppUserForgotPasswordRequestDto>({
            query: (data) => ({
                url: '/v1/noauth/verifyConfirmationCode',
                method: 'POST',
                data,
            }),
            invalidatesTags: [{ type: 'OnboardDTO', id: 'LIST' }],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetAnonymousLoggedUserQuery,
    useLazyGetAnonymousLoggedUserQuery,
    useVerifyConfirmationCodeMutation,
    useSendAnonymousVerificationCodeMutation,
    useVerifyAnonymousCodeMutation,
    useLazyGetAnonymousUploadUrlQuery,
    usePostAnonymousUploadUrlMutation,
    useEditAnonymousDocumentMutation,
    useSubmitAnonymousDocumentsMutation,
    useGetAnonymousPdfKeyQuery,
    useGetAnonymousDocumentWithDownloadUrlQuery,
    useGetLenderDocumentWithDownloadUrlQuery,
    useGetSessionQuery,
    useLazyGetSessionQuery,
    useRequestSessionExtensionMutation,
    useVerifySessionExtensionMutation,
    useGetAnonymousLoansQuery,
} = anonymousApi;



