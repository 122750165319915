import { _ as a } from "./index-BmyyIj4u.js";
import { forwardRef as o, createElement as t } from "react";
import { $ as f } from "./index-CaNEl95H.js";
const d = /* @__PURE__ */ o((e, r) => /* @__PURE__ */ t(f.span, a({}, e, {
  ref: r,
  style: {
    // See: https://github.com/twbs/bootstrap/blob/master/scss/mixins/_screen-reader.scss
    position: "absolute",
    border: 0,
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    wordWrap: "normal",
    ...e.style
  }
}))), p = d;
export {
  p as $,
  d as a
};
