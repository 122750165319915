import { FormElementV2ResponseDto } from "src/backend";

export type FileSizeUnit = 'B' | 'kB' | 'MB' | 'GB' | 'TB';

type FolderSizeUnit = `${number} ${'items' | 'item'}`;

type EmptyUnit = '-';

const unitList: FileSizeUnit[] = ['B', 'kB', 'MB', 'GB', 'TB'];

export const getHumanFileSize = (
    storageType: FormElementV2ResponseDto['storageType'],
    bytes: number,
    children: FormElementV2ResponseDto[]
): `${string} ${FileSizeUnit}` | EmptyUnit | FolderSizeUnit => {
    if (storageType === 'FOLDER') {
        const answeredChildrenCount = children.filter(child => !!child?.answer).length;
        if (answeredChildrenCount === 0) return '0 items';
        return `${answeredChildrenCount} ${answeredChildrenCount === 1 ? 'item' : 'items'}`;
    }
    if (!bytes) return '-';
    // handle bytes null
    const index = Math.floor(Math.log(bytes) / Math.log(1024));
    const unit: FileSizeUnit = unitList[index];
    const size = (bytes / Math.pow(1024, index)).toFixed(0);
    return `${size} ${unit}`;
};