import type { AppUserDTO2 } from "src/backend"
import type { AppUserDTO2Extended } from "src/types/user"

import { getInitials } from "../get-initials"
import { getUserDisplayName } from "./get-user-display-name"

export const extendAppUserDTO = (user: AppUserDTO2): AppUserDTO2Extended => {
    if (!user) {
        return null;
    }
    return {
        ...user,
        initials: getInitials(getUserDisplayName(user)),
        displayName: getUserDisplayName(user),
    }
}