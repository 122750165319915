
import { BlockType, TemplateResponseDto, TemplatesResponseDto } from 'src/backend';

import { baseApi } from './baseApi';

const sortByTemplateName = (a: TemplateResponseDto, b: TemplateResponseDto) => a.name.localeCompare(b.name, undefined, { numeric: true });
const reduceTemplates = (acc: Record<BlockType, TemplateResponseDto[]>, template: TemplateResponseDto) => {
    acc[template.blockType] = acc[template.blockType] || [];
    acc[template.blockType].push(template);
    return acc;
}

export const templateApi = baseApi.enhanceEndpoints({ addTagTypes: ['TemplateResponseDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getTemplates: build.query<Record<BlockType, TemplateResponseDto[]>, void>({
            query: () => ({
                url: '/v2/templates',
                method: 'GET',
            }),
            transformResponse: (response: TemplatesResponseDto) => {
                const sortedTemplates = response.templates.toSorted(sortByTemplateName);
                return sortedTemplates.reduce(reduceTemplates, {} as Record<BlockType, TemplateResponseDto[]>);
            },
            providesTags: [{ type: 'TemplateResponseDto', id: "LIST" }]
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetTemplatesQuery,
} = templateApi;



