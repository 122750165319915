import * as L from "react";
import { useLayoutEffect as Bt, useEffect as Ht } from "react";
import * as Wt from "react-dom";
const Nt = ["top", "right", "bottom", "left"], j = Math.min, P = Math.max, nt = Math.round, tt = Math.floor, _ = (t) => ({
  x: t,
  y: t
}), Vt = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, It = {
  start: "end",
  end: "start"
};
function ft(t, e, n) {
  return P(t, j(e, n));
}
function N(t, e) {
  return typeof t == "function" ? t(e) : t;
}
function V(t) {
  return t.split("-")[0];
}
function G(t) {
  return t.split("-")[1];
}
function ut(t) {
  return t === "x" ? "y" : "x";
}
function dt(t) {
  return t === "y" ? "height" : "width";
}
function J(t) {
  return ["top", "bottom"].includes(V(t)) ? "y" : "x";
}
function mt(t) {
  return ut(J(t));
}
function zt(t, e, n) {
  n === void 0 && (n = !1);
  const i = G(t), o = mt(t), r = dt(o);
  let s = o === "x" ? i === (n ? "end" : "start") ? "right" : "left" : i === "start" ? "bottom" : "top";
  return e.reference[r] > e.floating[r] && (s = it(s)), [s, it(s)];
}
function jt(t) {
  const e = it(t);
  return [at(t), e, at(e)];
}
function at(t) {
  return t.replace(/start|end/g, (e) => It[e]);
}
function _t(t, e, n) {
  const i = ["left", "right"], o = ["right", "left"], r = ["top", "bottom"], s = ["bottom", "top"];
  switch (t) {
    case "top":
    case "bottom":
      return n ? e ? o : i : e ? i : o;
    case "left":
    case "right":
      return e ? r : s;
    default:
      return [];
  }
}
function Yt(t, e, n, i) {
  const o = G(t);
  let r = _t(V(t), n === "start", i);
  return o && (r = r.map((s) => s + "-" + o), e && (r = r.concat(r.map(at)))), r;
}
function it(t) {
  return t.replace(/left|right|bottom|top/g, (e) => Vt[e]);
}
function $t(t) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...t
  };
}
function Et(t) {
  return typeof t != "number" ? $t(t) : {
    top: t,
    right: t,
    bottom: t,
    left: t
  };
}
function ot(t) {
  return {
    ...t,
    top: t.y,
    left: t.x,
    right: t.x + t.width,
    bottom: t.y + t.height
  };
}
function xt(t, e, n) {
  let {
    reference: i,
    floating: o
  } = t;
  const r = J(e), s = mt(e), l = dt(s), c = V(e), f = r === "y", m = i.x + i.width / 2 - o.width / 2, u = i.y + i.height / 2 - o.height / 2, d = i[l] / 2 - o[l] / 2;
  let a;
  switch (c) {
    case "top":
      a = {
        x: m,
        y: i.y - o.height
      };
      break;
    case "bottom":
      a = {
        x: m,
        y: i.y + i.height
      };
      break;
    case "right":
      a = {
        x: i.x + i.width,
        y: u
      };
      break;
    case "left":
      a = {
        x: i.x - o.width,
        y: u
      };
      break;
    default:
      a = {
        x: i.x,
        y: i.y
      };
  }
  switch (G(e)) {
    case "start":
      a[s] -= d * (n && f ? -1 : 1);
      break;
    case "end":
      a[s] += d * (n && f ? -1 : 1);
      break;
  }
  return a;
}
const Xt = async (t, e, n) => {
  const {
    placement: i = "bottom",
    strategy: o = "absolute",
    middleware: r = [],
    platform: s
  } = n, l = r.filter(Boolean), c = await (s.isRTL == null ? void 0 : s.isRTL(e));
  let f = await s.getElementRects({
    reference: t,
    floating: e,
    strategy: o
  }), {
    x: m,
    y: u
  } = xt(f, i, c), d = i, a = {}, g = 0;
  for (let p = 0; p < l.length; p++) {
    const {
      name: y,
      fn: h
    } = l[p], {
      x: w,
      y: b,
      data: A,
      reset: x
    } = await h({
      x: m,
      y: u,
      initialPlacement: i,
      placement: d,
      strategy: o,
      middlewareData: a,
      rects: f,
      platform: s,
      elements: {
        reference: t,
        floating: e
      }
    });
    if (m = w ?? m, u = b ?? u, a = {
      ...a,
      [y]: {
        ...a[y],
        ...A
      }
    }, x && g <= 50) {
      g++, typeof x == "object" && (x.placement && (d = x.placement), x.rects && (f = x.rects === !0 ? await s.getElementRects({
        reference: t,
        floating: e,
        strategy: o
      }) : x.rects), {
        x: m,
        y: u
      } = xt(f, d, c)), p = -1;
      continue;
    }
  }
  return {
    x: m,
    y: u,
    placement: d,
    strategy: o,
    middlewareData: a
  };
};
async function Q(t, e) {
  var n;
  e === void 0 && (e = {});
  const {
    x: i,
    y: o,
    platform: r,
    rects: s,
    elements: l,
    strategy: c
  } = t, {
    boundary: f = "clippingAncestors",
    rootBoundary: m = "viewport",
    elementContext: u = "floating",
    altBoundary: d = !1,
    padding: a = 0
  } = N(e, t), g = Et(a), y = l[d ? u === "floating" ? "reference" : "floating" : u], h = ot(await r.getClippingRect({
    element: (n = await (r.isElement == null ? void 0 : r.isElement(y))) == null || n ? y : y.contextElement || await (r.getDocumentElement == null ? void 0 : r.getDocumentElement(l.floating)),
    boundary: f,
    rootBoundary: m,
    strategy: c
  })), w = u === "floating" ? {
    ...s.floating,
    x: i,
    y: o
  } : s.reference, b = await (r.getOffsetParent == null ? void 0 : r.getOffsetParent(l.floating)), A = await (r.isElement == null ? void 0 : r.isElement(b)) ? await (r.getScale == null ? void 0 : r.getScale(b)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, x = ot(r.convertOffsetParentRelativeRectToViewportRelativeRect ? await r.convertOffsetParentRelativeRectToViewportRelativeRect({
    rect: w,
    offsetParent: b,
    strategy: c
  }) : w);
  return {
    top: (h.top - x.top + g.top) / A.y,
    bottom: (x.bottom - h.bottom + g.bottom) / A.y,
    left: (h.left - x.left + g.left) / A.x,
    right: (x.right - h.right + g.right) / A.x
  };
}
const wt = (t) => ({
  name: "arrow",
  options: t,
  async fn(e) {
    const {
      x: n,
      y: i,
      placement: o,
      rects: r,
      platform: s,
      elements: l
    } = e, {
      element: c,
      padding: f = 0
    } = N(t, e) || {};
    if (c == null)
      return {};
    const m = Et(f), u = {
      x: n,
      y: i
    }, d = mt(o), a = dt(d), g = await s.getDimensions(c), p = d === "y", y = p ? "top" : "left", h = p ? "bottom" : "right", w = p ? "clientHeight" : "clientWidth", b = r.reference[a] + r.reference[d] - u[d] - r.floating[a], A = u[d] - r.reference[d], x = await (s.getOffsetParent == null ? void 0 : s.getOffsetParent(c));
    let R = x ? x[w] : 0;
    (!R || !await (s.isElement == null ? void 0 : s.isElement(x))) && (R = l.floating[w] || r.floating[a]);
    const E = b / 2 - A / 2, T = R / 2 - g[a] / 2 - 1, D = j(m[y], T), H = j(m[h], T), O = D, W = R - g[a] - H, S = R / 2 - g[a] / 2 + E, C = ft(O, S, W), v = G(o) != null && S != C && r.reference[a] / 2 - (S < O ? D : H) - g[a] / 2 < 0 ? S < O ? O - S : W - S : 0;
    return {
      [d]: u[d] - v,
      data: {
        [d]: C,
        centerOffset: S - C + v
      }
    };
  }
}), me = function(t) {
  return t === void 0 && (t = {}), {
    name: "flip",
    options: t,
    async fn(e) {
      var n;
      const {
        placement: i,
        middlewareData: o,
        rects: r,
        initialPlacement: s,
        platform: l,
        elements: c
      } = e, {
        mainAxis: f = !0,
        crossAxis: m = !0,
        fallbackPlacements: u,
        fallbackStrategy: d = "bestFit",
        fallbackAxisSideDirection: a = "none",
        flipAlignment: g = !0,
        ...p
      } = N(t, e), y = V(i), h = V(s) === s, w = await (l.isRTL == null ? void 0 : l.isRTL(c.floating)), b = u || (h || !g ? [it(s)] : jt(s));
      !u && a !== "none" && b.push(...Yt(s, g, a, w));
      const A = [s, ...b], x = await Q(e, p), R = [];
      let E = ((n = o.flip) == null ? void 0 : n.overflows) || [];
      if (f && R.push(x[y]), m) {
        const O = zt(i, r, w);
        R.push(x[O[0]], x[O[1]]);
      }
      if (E = [...E, {
        placement: i,
        overflows: R
      }], !R.every((O) => O <= 0)) {
        var T, D;
        const O = (((T = o.flip) == null ? void 0 : T.index) || 0) + 1, W = A[O];
        if (W)
          return {
            data: {
              index: O,
              overflows: E
            },
            reset: {
              placement: W
            }
          };
        let S = (D = E.filter((C) => C.overflows[0] <= 0).sort((C, F) => C.overflows[1] - F.overflows[1])[0]) == null ? void 0 : D.placement;
        if (!S)
          switch (d) {
            case "bestFit": {
              var H;
              const C = (H = E.map((F) => [F.placement, F.overflows.filter((v) => v > 0).reduce((v, X) => v + X, 0)]).sort((F, v) => F[1] - v[1])[0]) == null ? void 0 : H[0];
              C && (S = C);
              break;
            }
            case "initialPlacement":
              S = s;
              break;
          }
        if (i !== S)
          return {
            reset: {
              placement: S
            }
          };
      }
      return {};
    }
  };
};
function yt(t, e) {
  return {
    top: t.top - e.height,
    right: t.right - e.width,
    bottom: t.bottom - e.height,
    left: t.left - e.width
  };
}
function bt(t) {
  return Nt.some((e) => t[e] >= 0);
}
const ge = function(t) {
  return t === void 0 && (t = {}), {
    name: "hide",
    options: t,
    async fn(e) {
      const {
        rects: n
      } = e, {
        strategy: i = "referenceHidden",
        ...o
      } = N(t, e);
      switch (i) {
        case "referenceHidden": {
          const r = await Q(e, {
            ...o,
            elementContext: "reference"
          }), s = yt(r, n.reference);
          return {
            data: {
              referenceHiddenOffsets: s,
              referenceHidden: bt(s)
            }
          };
        }
        case "escaped": {
          const r = await Q(e, {
            ...o,
            altBoundary: !0
          }), s = yt(r, n.floating);
          return {
            data: {
              escapedOffsets: s,
              escaped: bt(s)
            }
          };
        }
        default:
          return {};
      }
    }
  };
};
async function qt(t, e) {
  const {
    placement: n,
    platform: i,
    elements: o
  } = t, r = await (i.isRTL == null ? void 0 : i.isRTL(o.floating)), s = V(n), l = G(n), c = J(n) === "y", f = ["left", "top"].includes(s) ? -1 : 1, m = r && c ? -1 : 1, u = N(e, t);
  let {
    mainAxis: d,
    crossAxis: a,
    alignmentAxis: g
  } = typeof u == "number" ? {
    mainAxis: u,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: 0,
    crossAxis: 0,
    alignmentAxis: null,
    ...u
  };
  return l && typeof g == "number" && (a = l === "end" ? g * -1 : g), c ? {
    x: a * m,
    y: d * f
  } : {
    x: d * f,
    y: a * m
  };
}
const he = function(t) {
  return t === void 0 && (t = 0), {
    name: "offset",
    options: t,
    async fn(e) {
      const {
        x: n,
        y: i
      } = e, o = await qt(e, t);
      return {
        x: n + o.x,
        y: i + o.y,
        data: o
      };
    }
  };
}, pe = function(t) {
  return t === void 0 && (t = {}), {
    name: "shift",
    options: t,
    async fn(e) {
      const {
        x: n,
        y: i,
        placement: o
      } = e, {
        mainAxis: r = !0,
        crossAxis: s = !1,
        limiter: l = {
          fn: (y) => {
            let {
              x: h,
              y: w
            } = y;
            return {
              x: h,
              y: w
            };
          }
        },
        ...c
      } = N(t, e), f = {
        x: n,
        y: i
      }, m = await Q(e, c), u = J(V(o)), d = ut(u);
      let a = f[d], g = f[u];
      if (r) {
        const y = d === "y" ? "top" : "left", h = d === "y" ? "bottom" : "right", w = a + m[y], b = a - m[h];
        a = ft(w, a, b);
      }
      if (s) {
        const y = u === "y" ? "top" : "left", h = u === "y" ? "bottom" : "right", w = g + m[y], b = g - m[h];
        g = ft(w, g, b);
      }
      const p = l.fn({
        ...e,
        [d]: a,
        [u]: g
      });
      return {
        ...p,
        data: {
          x: p.x - n,
          y: p.y - i
        }
      };
    }
  };
}, xe = function(t) {
  return t === void 0 && (t = {}), {
    options: t,
    fn(e) {
      const {
        x: n,
        y: i,
        placement: o,
        rects: r,
        middlewareData: s
      } = e, {
        offset: l = 0,
        mainAxis: c = !0,
        crossAxis: f = !0
      } = N(t, e), m = {
        x: n,
        y: i
      }, u = J(o), d = ut(u);
      let a = m[d], g = m[u];
      const p = N(l, e), y = typeof p == "number" ? {
        mainAxis: p,
        crossAxis: 0
      } : {
        mainAxis: 0,
        crossAxis: 0,
        ...p
      };
      if (c) {
        const b = d === "y" ? "height" : "width", A = r.reference[d] - r.floating[b] + y.mainAxis, x = r.reference[d] + r.reference[b] - y.mainAxis;
        a < A ? a = A : a > x && (a = x);
      }
      if (f) {
        var h, w;
        const b = d === "y" ? "width" : "height", A = ["top", "left"].includes(V(o)), x = r.reference[u] - r.floating[b] + (A && ((h = s.offset) == null ? void 0 : h[u]) || 0) + (A ? 0 : y.crossAxis), R = r.reference[u] + r.reference[b] + (A ? 0 : ((w = s.offset) == null ? void 0 : w[u]) || 0) - (A ? y.crossAxis : 0);
        g < x ? g = x : g > R && (g = R);
      }
      return {
        [d]: a,
        [u]: g
      };
    }
  };
}, we = function(t) {
  return t === void 0 && (t = {}), {
    name: "size",
    options: t,
    async fn(e) {
      const {
        placement: n,
        rects: i,
        platform: o,
        elements: r
      } = e, {
        apply: s = () => {
        },
        ...l
      } = N(t, e), c = await Q(e, l), f = V(n), m = G(n), u = J(n) === "y", {
        width: d,
        height: a
      } = i.floating;
      let g, p;
      f === "top" || f === "bottom" ? (g = f, p = m === (await (o.isRTL == null ? void 0 : o.isRTL(r.floating)) ? "start" : "end") ? "left" : "right") : (p = f, g = m === "end" ? "top" : "bottom");
      const y = a - c[g], h = d - c[p], w = !e.middlewareData.shift;
      let b = y, A = h;
      if (u) {
        const R = d - c.left - c.right;
        A = m || w ? j(h, R) : R;
      } else {
        const R = a - c.top - c.bottom;
        b = m || w ? j(y, R) : R;
      }
      if (w && !m) {
        const R = P(c.left, 0), E = P(c.right, 0), T = P(c.top, 0), D = P(c.bottom, 0);
        u ? A = d - 2 * (R !== 0 || E !== 0 ? R + E : P(c.left, c.right)) : b = a - 2 * (T !== 0 || D !== 0 ? T + D : P(c.top, c.bottom));
      }
      await s({
        ...e,
        availableWidth: A,
        availableHeight: b
      });
      const x = await o.getDimensions(r.floating);
      return d !== x.width || a !== x.height ? {
        reset: {
          rects: !0
        }
      } : {};
    }
  };
};
function Y(t) {
  return St(t) ? (t.nodeName || "").toLowerCase() : "#document";
}
function M(t) {
  var e;
  return (t == null || (e = t.ownerDocument) == null ? void 0 : e.defaultView) || window;
}
function z(t) {
  var e;
  return (e = (St(t) ? t.ownerDocument : t.document) || window.document) == null ? void 0 : e.documentElement;
}
function St(t) {
  return t instanceof Node || t instanceof M(t).Node;
}
function I(t) {
  return t instanceof Element || t instanceof M(t).Element;
}
function B(t) {
  return t instanceof HTMLElement || t instanceof M(t).HTMLElement;
}
function At(t) {
  return typeof ShadowRoot > "u" ? !1 : t instanceof ShadowRoot || t instanceof M(t).ShadowRoot;
}
function Z(t) {
  const {
    overflow: e,
    overflowX: n,
    overflowY: i,
    display: o
  } = k(t);
  return /auto|scroll|overlay|hidden|clip/.test(e + i + n) && !["inline", "contents"].includes(o);
}
function Ut(t) {
  return ["table", "td", "th"].includes(Y(t));
}
function gt(t) {
  const e = ht(), n = k(t);
  return n.transform !== "none" || n.perspective !== "none" || (n.containerType ? n.containerType !== "normal" : !1) || !e && (n.backdropFilter ? n.backdropFilter !== "none" : !1) || !e && (n.filter ? n.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((i) => (n.willChange || "").includes(i)) || ["paint", "layout", "strict", "content"].some((i) => (n.contain || "").includes(i));
}
function Kt(t) {
  let e = K(t);
  for (; B(e) && !ct(e); ) {
    if (gt(e))
      return e;
    e = K(e);
  }
  return null;
}
function ht() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function ct(t) {
  return ["html", "body", "#document"].includes(Y(t));
}
function k(t) {
  return M(t).getComputedStyle(t);
}
function lt(t) {
  return I(t) ? {
    scrollLeft: t.scrollLeft,
    scrollTop: t.scrollTop
  } : {
    scrollLeft: t.pageXOffset,
    scrollTop: t.pageYOffset
  };
}
function K(t) {
  if (Y(t) === "html")
    return t;
  const e = (
    // Step into the shadow DOM of the parent of a slotted node.
    t.assignedSlot || // DOM Element detected.
    t.parentNode || // ShadowRoot detected.
    At(t) && t.host || // Fallback.
    z(t)
  );
  return At(e) ? e.host : e;
}
function Lt(t) {
  const e = K(t);
  return ct(e) ? t.ownerDocument ? t.ownerDocument.body : t.body : B(e) && Z(e) ? e : Lt(e);
}
function rt(t, e) {
  var n;
  e === void 0 && (e = []);
  const i = Lt(t), o = i === ((n = t.ownerDocument) == null ? void 0 : n.body), r = M(i);
  return o ? e.concat(r, r.visualViewport || [], Z(i) ? i : []) : e.concat(i, rt(i));
}
function Tt(t) {
  const e = k(t);
  let n = parseFloat(e.width) || 0, i = parseFloat(e.height) || 0;
  const o = B(t), r = o ? t.offsetWidth : n, s = o ? t.offsetHeight : i, l = nt(n) !== r || nt(i) !== s;
  return l && (n = r, i = s), {
    width: n,
    height: i,
    $: l
  };
}
function pt(t) {
  return I(t) ? t : t.contextElement;
}
function U(t) {
  const e = pt(t);
  if (!B(e))
    return _(1);
  const n = e.getBoundingClientRect(), {
    width: i,
    height: o,
    $: r
  } = Tt(e);
  let s = (r ? nt(n.width) : n.width) / i, l = (r ? nt(n.height) : n.height) / o;
  return (!s || !Number.isFinite(s)) && (s = 1), (!l || !Number.isFinite(l)) && (l = 1), {
    x: s,
    y: l
  };
}
const Gt = /* @__PURE__ */ _(0);
function Pt(t) {
  const e = M(t);
  return !ht() || !e.visualViewport ? Gt : {
    x: e.visualViewport.offsetLeft,
    y: e.visualViewport.offsetTop
  };
}
function Jt(t, e, n) {
  return e === void 0 && (e = !1), !n || e && n !== M(t) ? !1 : e;
}
function $(t, e, n, i) {
  e === void 0 && (e = !1), n === void 0 && (n = !1);
  const o = t.getBoundingClientRect(), r = pt(t);
  let s = _(1);
  e && (i ? I(i) && (s = U(i)) : s = U(t));
  const l = Jt(r, n, i) ? Pt(r) : _(0);
  let c = (o.left + l.x) / s.x, f = (o.top + l.y) / s.y, m = o.width / s.x, u = o.height / s.y;
  if (r) {
    const d = M(r), a = i && I(i) ? M(i) : i;
    let g = d.frameElement;
    for (; g && i && a !== d; ) {
      const p = U(g), y = g.getBoundingClientRect(), h = k(g), w = y.left + (g.clientLeft + parseFloat(h.paddingLeft)) * p.x, b = y.top + (g.clientTop + parseFloat(h.paddingTop)) * p.y;
      c *= p.x, f *= p.y, m *= p.x, u *= p.y, c += w, f += b, g = M(g).frameElement;
    }
  }
  return ot({
    width: m,
    height: u,
    x: c,
    y: f
  });
}
function Qt(t) {
  let {
    rect: e,
    offsetParent: n,
    strategy: i
  } = t;
  const o = B(n), r = z(n);
  if (n === r)
    return e;
  let s = {
    scrollLeft: 0,
    scrollTop: 0
  }, l = _(1);
  const c = _(0);
  if ((o || !o && i !== "fixed") && ((Y(n) !== "body" || Z(r)) && (s = lt(n)), B(n))) {
    const f = $(n);
    l = U(n), c.x = f.x + n.clientLeft, c.y = f.y + n.clientTop;
  }
  return {
    width: e.width * l.x,
    height: e.height * l.y,
    x: e.x * l.x - s.scrollLeft * l.x + c.x,
    y: e.y * l.y - s.scrollTop * l.y + c.y
  };
}
function Zt(t) {
  return Array.from(t.getClientRects());
}
function Mt(t) {
  return $(z(t)).left + lt(t).scrollLeft;
}
function te(t) {
  const e = z(t), n = lt(t), i = t.ownerDocument.body, o = P(e.scrollWidth, e.clientWidth, i.scrollWidth, i.clientWidth), r = P(e.scrollHeight, e.clientHeight, i.scrollHeight, i.clientHeight);
  let s = -n.scrollLeft + Mt(t);
  const l = -n.scrollTop;
  return k(i).direction === "rtl" && (s += P(e.clientWidth, i.clientWidth) - o), {
    width: o,
    height: r,
    x: s,
    y: l
  };
}
function ee(t, e) {
  const n = M(t), i = z(t), o = n.visualViewport;
  let r = i.clientWidth, s = i.clientHeight, l = 0, c = 0;
  if (o) {
    r = o.width, s = o.height;
    const f = ht();
    (!f || f && e === "fixed") && (l = o.offsetLeft, c = o.offsetTop);
  }
  return {
    width: r,
    height: s,
    x: l,
    y: c
  };
}
function ne(t, e) {
  const n = $(t, !0, e === "fixed"), i = n.top + t.clientTop, o = n.left + t.clientLeft, r = B(t) ? U(t) : _(1), s = t.clientWidth * r.x, l = t.clientHeight * r.y, c = o * r.x, f = i * r.y;
  return {
    width: s,
    height: l,
    x: c,
    y: f
  };
}
function Rt(t, e, n) {
  let i;
  if (e === "viewport")
    i = ee(t, n);
  else if (e === "document")
    i = te(z(t));
  else if (I(e))
    i = ne(e, n);
  else {
    const o = Pt(t);
    i = {
      ...e,
      x: e.x - o.x,
      y: e.y - o.y
    };
  }
  return ot(i);
}
function Dt(t, e) {
  const n = K(t);
  return n === e || !I(n) || ct(n) ? !1 : k(n).position === "fixed" || Dt(n, e);
}
function ie(t, e) {
  const n = e.get(t);
  if (n)
    return n;
  let i = rt(t).filter((l) => I(l) && Y(l) !== "body"), o = null;
  const r = k(t).position === "fixed";
  let s = r ? K(t) : t;
  for (; I(s) && !ct(s); ) {
    const l = k(s), c = gt(s);
    !c && l.position === "fixed" && (o = null), (r ? !c && !o : !c && l.position === "static" && !!o && ["absolute", "fixed"].includes(o.position) || Z(s) && !c && Dt(t, s)) ? i = i.filter((m) => m !== s) : o = l, s = K(s);
  }
  return e.set(t, i), i;
}
function oe(t) {
  let {
    element: e,
    boundary: n,
    rootBoundary: i,
    strategy: o
  } = t;
  const s = [...n === "clippingAncestors" ? ie(e, this._c) : [].concat(n), i], l = s[0], c = s.reduce((f, m) => {
    const u = Rt(e, m, o);
    return f.top = P(u.top, f.top), f.right = j(u.right, f.right), f.bottom = j(u.bottom, f.bottom), f.left = P(u.left, f.left), f;
  }, Rt(e, l, o));
  return {
    width: c.right - c.left,
    height: c.bottom - c.top,
    x: c.left,
    y: c.top
  };
}
function re(t) {
  return Tt(t);
}
function se(t, e, n) {
  const i = B(e), o = z(e), r = n === "fixed", s = $(t, !0, r, e);
  let l = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const c = _(0);
  if (i || !i && !r)
    if ((Y(e) !== "body" || Z(o)) && (l = lt(e)), i) {
      const f = $(e, !0, r, e);
      c.x = f.x + e.clientLeft, c.y = f.y + e.clientTop;
    } else o && (c.x = Mt(o));
  return {
    x: s.left + l.scrollLeft - c.x,
    y: s.top + l.scrollTop - c.y,
    width: s.width,
    height: s.height
  };
}
function vt(t, e) {
  return !B(t) || k(t).position === "fixed" ? null : e ? e(t) : t.offsetParent;
}
function kt(t, e) {
  const n = M(t);
  if (!B(t))
    return n;
  let i = vt(t, e);
  for (; i && Ut(i) && k(i).position === "static"; )
    i = vt(i, e);
  return i && (Y(i) === "html" || Y(i) === "body" && k(i).position === "static" && !gt(i)) ? n : i || Kt(t) || n;
}
const ce = async function(t) {
  let {
    reference: e,
    floating: n,
    strategy: i
  } = t;
  const o = this.getOffsetParent || kt, r = this.getDimensions;
  return {
    reference: se(e, await o(n), i),
    floating: {
      x: 0,
      y: 0,
      ...await r(n)
    }
  };
};
function le(t) {
  return k(t).direction === "rtl";
}
const fe = {
  convertOffsetParentRelativeRectToViewportRelativeRect: Qt,
  getDocumentElement: z,
  getClippingRect: oe,
  getOffsetParent: kt,
  getElementRects: ce,
  getClientRects: Zt,
  getDimensions: re,
  getScale: U,
  isElement: I,
  isRTL: le
};
function ae(t, e) {
  let n = null, i;
  const o = z(t);
  function r() {
    clearTimeout(i), n && n.disconnect(), n = null;
  }
  function s(l, c) {
    l === void 0 && (l = !1), c === void 0 && (c = 1), r();
    const {
      left: f,
      top: m,
      width: u,
      height: d
    } = t.getBoundingClientRect();
    if (l || e(), !u || !d)
      return;
    const a = tt(m), g = tt(o.clientWidth - (f + u)), p = tt(o.clientHeight - (m + d)), y = tt(f), w = {
      rootMargin: -a + "px " + -g + "px " + -p + "px " + -y + "px",
      threshold: P(0, j(1, c)) || 1
    };
    let b = !0;
    function A(x) {
      const R = x[0].intersectionRatio;
      if (R !== c) {
        if (!b)
          return s();
        R ? s(!1, R) : i = setTimeout(() => {
          s(!1, 1e-7);
        }, 100);
      }
      b = !1;
    }
    try {
      n = new IntersectionObserver(A, {
        ...w,
        // Handle <iframe>s
        root: o.ownerDocument
      });
    } catch {
      n = new IntersectionObserver(A, w);
    }
    n.observe(t);
  }
  return s(!0), r;
}
function ye(t, e, n, i) {
  i === void 0 && (i = {});
  const {
    ancestorScroll: o = !0,
    ancestorResize: r = !0,
    elementResize: s = typeof ResizeObserver == "function",
    layoutShift: l = typeof IntersectionObserver == "function",
    animationFrame: c = !1
  } = i, f = pt(t), m = o || r ? [...f ? rt(f) : [], ...rt(e)] : [];
  m.forEach((h) => {
    o && h.addEventListener("scroll", n, {
      passive: !0
    }), r && h.addEventListener("resize", n);
  });
  const u = f && l ? ae(f, n) : null;
  let d = -1, a = null;
  s && (a = new ResizeObserver((h) => {
    let [w] = h;
    w && w.target === f && a && (a.unobserve(e), cancelAnimationFrame(d), d = requestAnimationFrame(() => {
      a && a.observe(e);
    })), n();
  }), f && !c && a.observe(f), a.observe(e));
  let g, p = c ? $(t) : null;
  c && y();
  function y() {
    const h = $(t);
    p && (h.x !== p.x || h.y !== p.y || h.width !== p.width || h.height !== p.height) && n(), p = h, g = requestAnimationFrame(y);
  }
  return n(), () => {
    m.forEach((h) => {
      o && h.removeEventListener("scroll", n), r && h.removeEventListener("resize", n);
    }), u && u(), a && a.disconnect(), a = null, c && cancelAnimationFrame(g);
  };
}
const ue = (t, e, n) => {
  const i = /* @__PURE__ */ new Map(), o = {
    platform: fe,
    ...n
  }, r = {
    ...o.platform,
    _c: i
  };
  return Xt(t, e, {
    ...o,
    platform: r
  });
}, be = (t) => {
  function e(n) {
    return {}.hasOwnProperty.call(n, "current");
  }
  return {
    name: "arrow",
    options: t,
    fn(n) {
      const {
        element: i,
        padding: o
      } = typeof t == "function" ? t(n) : t;
      return i && e(i) ? i.current != null ? wt({
        element: i.current,
        padding: o
      }).fn(n) : {} : i ? wt({
        element: i,
        padding: o
      }).fn(n) : {};
    }
  };
};
var et = typeof document < "u" ? Bt : Ht;
function st(t, e) {
  if (t === e)
    return !0;
  if (typeof t != typeof e)
    return !1;
  if (typeof t == "function" && t.toString() === e.toString())
    return !0;
  let n, i, o;
  if (t && e && typeof t == "object") {
    if (Array.isArray(t)) {
      if (n = t.length, n != e.length) return !1;
      for (i = n; i-- !== 0; )
        if (!st(t[i], e[i]))
          return !1;
      return !0;
    }
    if (o = Object.keys(t), n = o.length, n !== Object.keys(e).length)
      return !1;
    for (i = n; i-- !== 0; )
      if (!{}.hasOwnProperty.call(e, o[i]))
        return !1;
    for (i = n; i-- !== 0; ) {
      const r = o[i];
      if (!(r === "_owner" && t.$$typeof) && !st(t[r], e[r]))
        return !1;
    }
    return !0;
  }
  return t !== t && e !== e;
}
function Ft(t) {
  return typeof window > "u" ? 1 : (t.ownerDocument.defaultView || window).devicePixelRatio || 1;
}
function Ot(t, e) {
  const n = Ft(t);
  return Math.round(e * n) / n;
}
function Ct(t) {
  const e = L.useRef(t);
  return et(() => {
    e.current = t;
  }), e;
}
function Ae(t) {
  t === void 0 && (t = {});
  const {
    placement: e = "bottom",
    strategy: n = "absolute",
    middleware: i = [],
    platform: o,
    elements: {
      reference: r,
      floating: s
    } = {},
    transform: l = !0,
    whileElementsMounted: c,
    open: f
  } = t, [m, u] = L.useState({
    x: 0,
    y: 0,
    strategy: n,
    placement: e,
    middlewareData: {},
    isPositioned: !1
  }), [d, a] = L.useState(i);
  st(d, i) || a(i);
  const [g, p] = L.useState(null), [y, h] = L.useState(null), w = L.useCallback((v) => {
    v != R.current && (R.current = v, p(v));
  }, [p]), b = L.useCallback((v) => {
    v !== E.current && (E.current = v, h(v));
  }, [h]), A = r || g, x = s || y, R = L.useRef(null), E = L.useRef(null), T = L.useRef(m), D = Ct(c), H = Ct(o), O = L.useCallback(() => {
    if (!R.current || !E.current)
      return;
    const v = {
      placement: e,
      strategy: n,
      middleware: d
    };
    H.current && (v.platform = H.current), ue(R.current, E.current, v).then((X) => {
      const q = {
        ...X,
        isPositioned: !0
      };
      W.current && !st(T.current, q) && (T.current = q, Wt.flushSync(() => {
        u(q);
      }));
    });
  }, [d, e, n, H]);
  et(() => {
    f === !1 && T.current.isPositioned && (T.current.isPositioned = !1, u((v) => ({
      ...v,
      isPositioned: !1
    })));
  }, [f]);
  const W = L.useRef(!1);
  et(() => (W.current = !0, () => {
    W.current = !1;
  }), []), et(() => {
    if (A && (R.current = A), x && (E.current = x), A && x) {
      if (D.current)
        return D.current(A, x, O);
      O();
    }
  }, [A, x, O, D]);
  const S = L.useMemo(() => ({
    reference: R,
    floating: E,
    setReference: w,
    setFloating: b
  }), [w, b]), C = L.useMemo(() => ({
    reference: A,
    floating: x
  }), [A, x]), F = L.useMemo(() => {
    const v = {
      position: n,
      left: 0,
      top: 0
    };
    if (!C.floating)
      return v;
    const X = Ot(C.floating, m.x), q = Ot(C.floating, m.y);
    return l ? {
      ...v,
      transform: "translate(" + X + "px, " + q + "px)",
      ...Ft(C.floating) >= 1.5 && {
        willChange: "transform"
      }
    } : {
      position: n,
      left: X,
      top: q
    };
  }, [n, l, C.floating, m.x, m.y]);
  return L.useMemo(() => ({
    ...m,
    update: O,
    refs: S,
    elements: C,
    floatingStyles: F
  }), [m, O, S, C, F]);
}
export {
  we as a,
  be as b,
  ye as c,
  me as f,
  ge as h,
  xe as l,
  he as o,
  pe as s,
  Ae as u
};
