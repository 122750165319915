import { createContext as h, useMemo as $, createElement as x, useContext as v, useRef as P, useEffect as S, useCallback as _, useState as w, useLayoutEffect as k } from "react";
function j(e, n) {
  const t = /* @__PURE__ */ h(n);
  function c(r) {
    const { children: u, ...s } = r, f = $(
      () => s,
      Object.values(s)
    );
    return /* @__PURE__ */ x(t.Provider, {
      value: f
    }, u);
  }
  function o(r) {
    const u = v(t);
    if (u) return u;
    if (n !== void 0) return n;
    throw new Error(`\`${r}\` must be used within \`${e}\``);
  }
  return c.displayName = e + "Provider", [
    c,
    o
  ];
}
function B(e, n = []) {
  let t = [];
  function c(r, u) {
    const s = /* @__PURE__ */ h(u), f = t.length;
    t = [
      ...t,
      u
    ];
    function d(i) {
      const { scope: a, children: b, ...l } = i, C = (a == null ? void 0 : a[e][f]) || s, g = $(
        () => l,
        Object.values(l)
      );
      return /* @__PURE__ */ x(C.Provider, {
        value: g
      }, b);
    }
    function p(i, a) {
      const b = (a == null ? void 0 : a[e][f]) || s, l = v(b);
      if (l) return l;
      if (u !== void 0) return u;
      throw new Error(`\`${i}\` must be used within \`${r}\``);
    }
    return d.displayName = r + "Provider", [
      d,
      p
    ];
  }
  const o = () => {
    const r = t.map((u) => /* @__PURE__ */ h(u));
    return function(s) {
      const f = (s == null ? void 0 : s[e]) || r;
      return $(
        () => ({
          [`__scope${e}`]: {
            ...s,
            [e]: f
          }
        }),
        [
          s,
          f
        ]
      );
    };
  };
  return o.scopeName = e, [
    c,
    y(o, ...n)
  ];
}
function y(...e) {
  const n = e[0];
  if (e.length === 1) return n;
  const t = () => {
    const c = e.map(
      (o) => ({
        useScope: o(),
        scopeName: o.scopeName
      })
    );
    return function(r) {
      const u = c.reduce((s, { useScope: f, scopeName: d }) => {
        const i = f(r)[`__scope${d}`];
        return {
          ...s,
          ...i
        };
      }, {});
      return $(
        () => ({
          [`__scope${n.scopeName}`]: u
        }),
        [
          u
        ]
      );
    };
  };
  return t.scopeName = n.scopeName, t;
}
function E(e, n, { checkForDefaultPrevented: t = !0 } = {}) {
  return function(o) {
    if (e == null || e(o), t === !1 || !o.defaultPrevented) return n == null ? void 0 : n(o);
  };
}
function m(e) {
  const n = P(e);
  return S(() => {
    n.current = e;
  }), $(
    () => (...t) => {
      var c;
      return (c = n.current) === null || c === void 0 ? void 0 : c.call(n, ...t);
    },
    []
  );
}
function N({ prop: e, defaultProp: n, onChange: t = () => {
} }) {
  const [c, o] = R({
    defaultProp: n,
    onChange: t
  }), r = e !== void 0, u = r ? e : c, s = m(t), f = _((d) => {
    if (r) {
      const i = typeof d == "function" ? d(e) : d;
      i !== e && s(i);
    } else o(d);
  }, [
    r,
    e,
    o,
    s
  ]);
  return [
    u,
    f
  ];
}
function R({ defaultProp: e, onChange: n }) {
  const t = w(e), [c] = t, o = P(c), r = m(n);
  return S(() => {
    o.current !== c && (r(c), o.current = c);
  }, [
    c,
    o,
    r
  ]), t;
}
const O = globalThis != null && globalThis.document ? k : () => {
};
export {
  B as $,
  O as a,
  m as b,
  N as c,
  E as d,
  j as e
};
