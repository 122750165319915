import { useEffect as f } from "react";
import { b as s } from "./index-C8YCJLum.js";
function r(a, e = globalThis == null ? void 0 : globalThis.document) {
  const n = s(a);
  f(() => {
    const o = (d) => {
      d.key === "Escape" && n(d);
    };
    return e.addEventListener("keydown", o), () => e.removeEventListener("keydown", o);
  }, [
    n,
    e
  ]);
}
export {
  r as $
};
