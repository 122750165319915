import { a as O } from "./utils-CJ9afRe1.js";
const y = (d) => typeof d == "boolean" ? "".concat(d) : d === 0 ? "0" : d, o = O, j = (d, u) => (n) => {
  var s;
  if ((u == null ? void 0 : u.variants) == null) return o(d, n == null ? void 0 : n.class, n == null ? void 0 : n.className);
  const { variants: f, defaultVariants: a } = u, v = Object.keys(f).map((e) => {
    const t = n == null ? void 0 : n[e], i = a == null ? void 0 : a[e];
    if (t === null) return null;
    const l = y(t) || y(i);
    return f[e][l];
  }), c = n && Object.entries(n).reduce((e, t) => {
    let [i, l] = t;
    return l === void 0 || (e[i] = l), e;
  }, {}), N = u == null || (s = u.compoundVariants) === null || s === void 0 ? void 0 : s.reduce((e, t) => {
    let { class: i, className: l, ...V } = t;
    return Object.entries(V).every((C) => {
      let [m, r] = C;
      return Array.isArray(r) ? r.includes({
        ...a,
        ...c
      }[m]) : {
        ...a,
        ...c
      }[m] === r;
    }) ? [
      ...e,
      i,
      l
    ] : e;
  }, []);
  return o(d, v, N, n == null ? void 0 : n.class, n == null ? void 0 : n.className);
};
export {
  j as c
};
