import * as a from "react";
import { a as o } from "./index-C8YCJLum.js";
const r = a.useId || (() => {
});
let f = 0;
function i($) {
  const [e, n] = a.useState(r());
  return o(() => {
    n(
      (t) => t ?? String(f++)
    );
  }, [
    $
  ]), e ? `radix-${e}` : "";
}
export {
  i as $
};
