import { LoanPhaseCategoryType, MessageDto, MessageLabel, MessagePageDto, MessageSearchPageDto, MessageSendDto, MessageThreadDto, MessageUnreadDto, MessageUpdateDto } from 'src/backend';
import { OriginalMessagePayload } from 'src/types/chat';
import { AppUserDTO2Extended } from 'src/types/user';
import { stripHTMLFromString } from 'src/utils/strip-html-from-string'
import { extendAppUserDTO } from 'src/utils/user/extend-app-user-dto';

import { baseApi } from './baseApi';

export interface MessageDtoExtended extends MessageDto {
    createdAtFormatted: string;
    textPreview: string;
}

export interface MessageThreadDtoExtended extends MessageThreadDto {
    createdByUser: AppUserDTO2Extended;
    lastMessage: MessageDtoExtended;
    createdAtFormatted: string;
}
interface MessagePageDtoExtended extends MessagePageDto {
    content: MessageDtoExtended[];
}

interface MessageSearchPageDtoExtended extends MessageSearchPageDto {
    content: MessageDtoExtended[];
}

const getFormattedDate = (date: string): string => {
    try {
        return new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        }).format(new Date(date))
    } catch (e) {
        return date;
    }
}

const extendMessageDto = (message: MessageDto): MessageDtoExtended => {
    return {
        ...message,
        // if we have updatedDate we will use it, otherwise we will use processedTime
        createdAtFormatted: getFormattedDate(message.updatedDate ? message.updatedDate : message.processedTime),
        textPreview: stripHTMLFromString(message.body),
    };
}

const extendDraftMessageDto = (message: MessageDto): MessageDtoExtended => {
    return {
        ...message,
        createdAtFormatted: getFormattedDate(message.updatedDate ? message.updatedDate : message.createdDate),
        textPreview: stripHTMLFromString(message.body),
    };
}

const extendThreadDto = (thread: MessageThreadDto): MessageThreadDtoExtended => {
    return {
        ...thread,
        createdAtFormatted: getFormattedDate(thread.lastMessageDate),
        lastMessage: thread.lastMessage ? extendMessageDto(thread.lastMessage) : null,
        createdByUser: thread.createdByUser ? extendAppUserDTO(thread.createdByUser) : null,
        users: thread.users.map(threadUser => ({
            ...threadUser,
            user: threadUser.user ? extendAppUserDTO(threadUser.user) : null,
        })),
    };
}

export const messageApi = baseApi.enhanceEndpoints({ addTagTypes: ['BasicLoanDto', 'ContactRelationDto', 'MessageSearchPageDto', 'MessageDraftCount', 'MessageThreadUnreadCount', 'MessageUnreadDto', 'MessageDto', 'MessageThreadDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getUnreadCountByCompany: build.query<MessageUnreadDto, { companyId: string, categories: LoanPhaseCategoryType[] }>({
            query: (data) => ({
                url: `/v1/messages/unreadForCompany/${data.companyId}`,
                method: 'GET',
                data,
            }),
            providesTags: ['MessageUnreadDto'],
        }),
        getUnreadCountByCompanyByCompanies: build.query<number, { companyIds: string[], categories: LoanPhaseCategoryType[] }>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const promises = _arg.companyIds.map(companyId => fetchWithBQ({ url: `/v1/messages/unreadForCompany/${companyId}`, method: 'GET' }));
                const results = await Promise.all(promises);
                const data = results.map(result => result.data as MessageUnreadDto);
                const flattenedData = data.reduce((acc, curr) => ({
                    ...acc,
                    ...curr.unreadMap
                }), {} as MessageUnreadDto['unreadMap']);
                const unreadCount = Object.values(flattenedData).reduce((acc, curr) => acc + curr, 0);
                return { data: unreadCount, error: null };
            },
            providesTags: ['MessageUnreadDto']
        }),
        getDraftCountByLoanId: build.query<number, { loanId: string }>({
            query: (data) => ({
                url: `/v1/messages/draft/${data.loanId}/count`,
                method: 'GET',
            }),
            providesTags: ['MessageDraftCount'],
        }),
        getLoanMessages: build.query<MessageDtoExtended[], { loanId: string; formElementId?: string; filterByUser?: string[]; page?: number; size?: number; }>({
            query: (data) => ({
                url: `/v1/messages`,
                method: 'GET',
                data,
            }),
            transformResponse: (response: MessageDtoExtended[]) => {
                // we need to enhance the response to add a formatted date
                return response.map(extendMessageDto);
            },
            providesTags: (result) => result?.map(({ id }) => ({ type: 'MessageDto', id })),
        }),
        makeMessageAsRead: build.mutation<MessageDto, { messageId: string, threadId: string }>({
            query: (data) => ({
                url: `/v1/messages/${data.messageId}/read`,
                method: 'POST',
            }),
            transformResponse: (response: MessageDtoExtended) => extendMessageDto(response),
            invalidatesTags: (_, __, _args) => [
                { type: "MessageThreadDto", id: 'LIST' },
            ],
        }),
        markMessageAsUnread: build.mutation<MessageDto, { messageId: string, threadId: string }>({
            query: (data) => ({
                url: `/v1/messages/${data.messageId}/unread`,
                method: 'POST',
            }),
            transformResponse: (response: MessageDtoExtended) => extendMessageDto(response),
            invalidatesTags: (result, _err, _args) => [
                'MessageThreadUnreadCount',
                'MessageUnreadDto',
                { type: "MessageThreadDto", id: 'LIST' },
                {
                    type: 'MessageThreadDto',
                    id: _args.threadId
                }
            ],
        }),
        userTypingInThread: build.mutation<void, { threadId: string }>({
            query: (data) => ({
                url: `/v1/messages/threads/userTyping/${data.threadId}`,
                method: 'POST',
            }),
        }),
        sendDraftMessage: build.mutation<MessageDtoExtended, MessageSendDto>({
            query: (data) => ({
                url: `/v1/messages/draft`,
                method: 'POST',
                data,
            }),
            transformResponse: (response: MessageDtoExtended) => extendDraftMessageDto(response),
            invalidatesTags: (result, _err, _args) => [
                !_err ? { type: "MessageDto", id: result.id } : null,
                { type: "MessageDto", id: 'LIST' },
                'MessageDraftCount'
            ],
        }),
        updateDraftMessage: build.mutation<MessageDtoExtended, { draftId: string; data: MessageSendDto }>({
            query: ({ draftId, data }) => ({
                url: `/v1/messages/draft/${draftId}`,
                method: 'PUT',
                data: data,
            }),
            transformResponse: (response: MessageDtoExtended) => extendDraftMessageDto(response),
            invalidatesTags: (result, _err, _args) => [
                !_err ? { type: "MessageDto", id: result.id } : null,
                { type: "MessageDto", id: 'LIST' },
            ],
        }),
        deleteDraftMessage: build.mutation<MessageDtoExtended, string>({
            query: (id) => ({
                url: `/v1/messages/draft/${id}`,
                method: 'DELETE'
            }),
            // optimistic update
            invalidatesTags: (result, _err, _args) => [
                'MessageDraftCount',
                { type: "MessageDto", id: result.id },
                { type: "MessageDto", id: 'LIST' },
            ],
        }),
        unmuteThread: build.mutation<string, string>({
            query: (id) => ({
                url: `/v1/messages/threads/unmute/${id}`,
                method: 'POST'
            }),
            invalidatesTags: (result, _err, _args) => [
                { type: "MessageThreadDto", id: 'LIST' },
                {
                    type: 'MessageThreadDto',
                    id: _args
                }
            ],
        }),

        muteThread: build.mutation<string, string>({
            query: (threadId) => ({
                url: `/v1/messages/threads/mute/${threadId}`,
                method: 'POST'
            }), invalidatesTags: (result, _err, _args) => [
                { type: "MessageThreadDto", id: 'LIST' },
                {
                    type: 'MessageThreadDto',
                    id: _args
                }
            ],
        }),
        sendMessage: build.mutation<MessageDtoExtended, MessageSendDto>({
            query: (data) => ({
                url: `/v1/messages`,
                method: 'POST',
                data,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                // optimistic update first page of the thread messages
                const { data: createdPost } = await queryFulfilled
                const patchResult = dispatch(messageApi.util.updateQueryData('getThreadMessages', {
                    threadId: createdPost.messageThread.id,
                    page: 0,
                    iLabels: []
                }, (draft) => {
                    if (draft) {
                        if (!draft.content.some(message => message.id === createdPost.id)) {
                            draft.content.unshift(extendMessageDto(createdPost))
                            // update cache tags

                        }
                    }
                }))
                queryFulfilled.catch(patchResult.undo)
                // optimistic update get thread last message

            },
            transformResponse: (response: MessageDtoExtended) => extendMessageDto(response),
            invalidatesTags: (result, _err, _args) => !_err ? [
                {
                    type: 'MessageThreadDto',
                    id: result.messageThread.id
                },
                { type: 'BasicLoanDto' as const, id: 'LIST' },
                // invalidate contact key contact if we have toEmails
                ...(_args.toUserEmails ? [{ type: 'ContactRelationDto' as const, id: 'LIST' }] : []),
                // if we don't have a threadId in args we will invalidate the list of threads
                ...(_args.messageThreadId ? [] : [{ type: 'MessageThreadDto' as const, id: 'LIST' }]),
            ] : [],
        }),
        deleteMessage: build.mutation<MessageDtoExtended, { messageId: string }>({
            query: (data) => ({
                url: `/v1/messages/${data.messageId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _err, _args) => [
                { type: 'MessageDto', id: _result.id },
                { type: "MessageDto", id: 'LIST' },
            ],
            transformResponse: (response: MessageDto) => extendMessageDto(response),
        }),
        getLoanThreads: build.query<MessageThreadDtoExtended[], { loanId: string; }>({
            query: (data) => ({
                url: `/v1/messages/threads`,
                method: 'GET',
                data,
            }),
            transformResponse: (response: MessageThreadDto[]) => {
                return response.map(extendThreadDto)
            },
            providesTags: (result, error, _args) => [
                ...(!error ? result.map(({ id }) => ({ type: 'MessageThreadDto' as const, id })) : []),
                { type: 'MessageThreadDto', id: _args.loanId },
                { type: "MessageThreadDto", id: "LIST" }
            ],
        }),
        getLoansThreads: build.query<MessageThreadDto[], { loanIds: string[]; labels: MessageLabel[]; page?: number; size?: number; }>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const promises = _arg.loanIds.map(loanId => fetchWithBQ({ url: `/v1/messages/threads`, method: 'GET', data: { loanId, labels: _arg.labels } }));
                const results = await Promise.all(promises);
                const data = results.map(result => result.data as MessageThreadDto[]);
                return { data: data.flat().filter((item => typeof item !== 'undefined')), error: null };
            },
            providesTags: (result, _err, _args) => [
                ...result.map(({ id }) => ({ type: 'MessageThreadDto' as const, id })),
                ..._args.loanIds.map(loanId => ({ type: 'MessageThreadDto' as const, id: loanId })),
                { type: "MessageThreadDto", id: "LIST" }
            ],
        }),
        markMessageAsImportant: build.mutation<MessageDto, string>({
            query: (messageId) => ({
                url: `/v1/messages/${messageId}/important`,
                method: 'POST',
            }),
            invalidatesTags: (_result, _err, messageId) => [
                { type: 'MessageDto', id: messageId },
                'MessageUnreadDto',
            ],
        }),
        threadUnreadCount: build.query<MessageUnreadDto, { threadId: string }>({
            query: (data) => ({
                url: `/v1/messages/threads/messages/countunread`,
                method: 'GET',
            }),
            providesTags: ['MessageUnreadDto'],
        }),
        markMessageAsUnimportant: build.mutation<MessageDto, string>({
            query: (messageId) => ({
                url: `/v1/messages/${messageId}/notimportant`,
                method: 'POST',
            }),
            invalidatesTags: (_result, _err, messageId) => [
                { type: 'MessageDto', id: messageId },
                'MessageUnreadDto',
            ],
        }),
        updateMessage: build.mutation<MessageDto, { messageId: string, payload: MessageUpdateDto, }>({
            query: (data) => ({
                url: `/v1/messages/${data.messageId}`,
                method: 'PUT',
                data: data.payload,
            }),
            invalidatesTags: (_result, _err, _args) => [
                { type: "MessageDto", id: 'LIST' },
            ],
        }),
        getThreadMessages: build.query<MessagePageDtoExtended, { threadId: string, iLabels?: MessageLabel[]; page?: number; size?: number; iMessageId?: string; iLoadFirstUnreadPage?: boolean; }>({
            query: (data) => ({
                url: `/v1/messages/threads/${data.threadId}/messages`,
                method: 'GET',
                data,
            }),
            transformResponse: (response: MessagePageDtoExtended) => {
                // we need to enhance the response to add a formatted date
                return ({
                    ...response,
                    content: [...response.content].reverse().map(extendMessageDto)
                })
            },
            providesTags: (result, _err, _args) => [
                ...result.content.map(({ id }) => ({ type: 'MessageDto' as const, id })),
                { type: "MessageDto", id: 'LIST' },
                { type: "MessageDto", id: _args.threadId },
                { type: "MessageDto", id: _args.page?.toString() ?? '0' },
                ...(_args.iLoadFirstUnreadPage ? ['MessageUnreadDto' as const] : [])
            ],
        }),

        getDraftMessages: build.query<MessageDtoExtended[], { loanId: string }>({
            query: (data) => ({
                url: `/v1/messages/draft/${data.loanId}`,
                method: 'GET',
            }),
            transformResponse: (response: MessageDto[]) => {
                return response.map(extendDraftMessageDto)
            },
            providesTags: (result) => [
                { type: "MessageDto" as const, id: "LIST" },
                ...(result ? result.map(({ id }) => ({ type: 'MessageDto' as const, id })) : [])
            ],
        }),
        getUnreadMessages: build.query<MessageDtoExtended[], { loanId: string, iLabels?: MessageLabel[]; page?: number; size?: number; iMessageId?: string; iLoadFirstUnreadPage?: boolean; }>({
            query: (data) => ({
                url: `/v1/messages/unread`,
                method: 'GET',
                data,
            }),
            transformResponse: (response: MessageDto[]) => {
                // we need to enhance the response to add a formatted date
                return response.map(extendMessageDto)
            },
            providesTags: (result, _err, _args) => [
                ...result.map(({ id }) => ({ type: 'MessageDto' as const, id })),
                { type: "MessageDto", id: 'LIST' },
                { type: "MessageDto", id: _args.loanId },
                { type: "MessageDto", id: _args.page?.toString() ?? '0' },
                ...(_args.iLoadFirstUnreadPage ? ['MessageUnreadDto' as const] : [])
            ],
        }),
        unReadMessagesForThread: build.query<number, { threadId: string }>({
            query: (data) => ({
                url: `/v1/messages/threads/${data.threadId}/countUnread`,
                method: 'GET',
            }),
            providesTags: ['MessageThreadUnreadCount'],
        }),
        getOriginalMessageAsHtml: build.query<string, { messageId: string }>({
            queryFn: async (_arg, _baseApi, _extraOptions, fetchWithBQ) => {
                const response = await fetchWithBQ({ url: `/v1/messages/originatorimplementation/${_arg.messageId}`, method: 'GET' });
                return { data: (response.data as OriginalMessagePayload).htmlContent, error: null };
            },
        }),
        forwardMessage: build.mutation<MessageDto, { messageId: string }>({
            query: (data) => ({
                url: `/v1/messages/threads/forwardMessage/${data.messageId}`,
                method: 'POST',
            }),
        }),
        getLoanThread: build.query<MessageThreadDtoExtended, { loanId: string, threadId: string }>({
            query: (data) => ({
                url: `/v1/messages/threads/${data.threadId}`,
                method: 'GET',
                data
            }),
            transformResponse: extendThreadDto,
            providesTags: (_, _2, args) => [{ type: 'MessageThreadDto', id: args.threadId }],
        }),
        searchMessages: build.query<MessageSearchPageDtoExtended, { textSearch?: string; loanId: string; iLabels?: MessageLabel[]; page?: number; threadId?: string; size?: number; order?: 'DESC' | 'ASC'; }>({
            query: (data) => ({
                url: `/v1/messages/search`,
                method: 'GET',
                data,
            }),
            transformResponse: (response: MessageSearchPageDto) => {
                // we need to enhance the response to add a formatted date
                return ({
                    ...response,
                    content: response.content.map(extendMessageDto)
                })
            },
            providesTags: [{ type: 'MessageSearchPageDto', id: "LIST" }]
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetDraftMessagesQuery,
    useGetLoanThreadQuery,
    useLazySearchMessagesQuery,
    useSearchMessagesQuery,
    useForwardMessageMutation,
    useUnReadMessagesForThreadQuery,
    useMarkMessageAsImportantMutation,
    useMarkMessageAsUnimportantMutation,
    useUpdateMessageMutation,
    useDeleteMessageMutation,
    useMakeMessageAsReadMutation,
    useMarkMessageAsUnreadMutation,
    useGetThreadMessagesQuery,
    useLazyGetThreadMessagesQuery,
    useGetLoanThreadsQuery,
    useGetLoansThreadsQuery,
    useUserTypingInThreadMutation,
    useSendMessageMutation,
    useSendDraftMessageMutation,
    useUnmuteThreadMutation,
    useMuteThreadMutation,
    useDeleteDraftMessageMutation,
    useUpdateDraftMessageMutation,
    useGetOriginalMessageAsHtmlQuery,
    useGetUnreadMessagesQuery,
    useLazyGetUnreadMessagesQuery,
    useLazyGetOriginalMessageAsHtmlQuery,
    useGetLoanMessagesQuery,
    useGetUnreadCountByCompanyByCompaniesQuery,
    useGetDraftCountByLoanIdQuery,
    useGetUnreadCountByCompanyQuery,
    useLazyGetUnreadCountByCompanyQuery,
    useLazyGetLoanThreadsQuery,
} = messageApi;



