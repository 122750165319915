import { useCallback as y, forwardRef as $, Children as s, isValidElement as a, createElement as u, cloneElement as m, Fragment as h } from "react";
function f() {
  return f = Object.assign ? Object.assign.bind() : function(e) {
    for (var r = 1; r < arguments.length; r++) {
      var n = arguments[r];
      for (var t in n) ({}).hasOwnProperty.call(n, t) && (e[t] = n[t]);
    }
    return e;
  }, f.apply(null, arguments);
}
function g(e, r) {
  typeof e == "function" ? e(r) : e != null && (e.current = r);
}
function b(...e) {
  return (r) => e.forEach(
    (n) => g(n, r)
  );
}
function P(...e) {
  return y(b(...e), e);
}
const v = /* @__PURE__ */ $((e, r) => {
  const { children: n, ...t } = e, c = s.toArray(n), l = c.find(C);
  if (l) {
    const o = l.props.children, i = c.map((p) => p === l ? s.count(o) > 1 ? s.only(null) : /* @__PURE__ */ a(o) ? o.props.children : null : p);
    return /* @__PURE__ */ u(d, f({}, t, {
      ref: r
    }), /* @__PURE__ */ a(o) ? /* @__PURE__ */ m(o, void 0, i) : null);
  }
  return /* @__PURE__ */ u(d, f({}, t, {
    ref: r
  }), n);
});
v.displayName = "Slot";
const d = /* @__PURE__ */ $((e, r) => {
  const { children: n, ...t } = e;
  return /* @__PURE__ */ a(n) ? /* @__PURE__ */ m(n, {
    ...E(t, n.props),
    ref: r ? b(r, n.ref) : n.ref
  }) : s.count(n) > 1 ? s.only(null) : null;
});
d.displayName = "SlotClone";
const x = ({ children: e }) => /* @__PURE__ */ u(h, null, e);
function C(e) {
  return /* @__PURE__ */ a(e) && e.type === x;
}
function E(e, r) {
  const n = {
    ...r
  };
  for (const t in r) {
    const c = e[t], l = r[t];
    /^on[A-Z]/.test(t) ? c && l ? n[t] = (...i) => {
      l(...i), c(...i);
    } : c && (n[t] = c) : t === "style" ? n[t] = {
      ...c,
      ...l
    } : t === "className" && (n[t] = [
      c,
      l
    ].filter(Boolean).join(" "));
  }
  return {
    ...e,
    ...n
  };
}
export {
  v as $,
  f as _,
  P as a,
  x as b
};
