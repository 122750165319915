
import { NoteRequestDto, NoteResponseDto, NoteStatus } from 'src/backend';
import { AppUserDTO2Extended } from 'src/types/user';
import { extendAppUserDTO } from 'src/utils/user/extend-app-user-dto';

import { baseApi } from './baseApi';

export interface NoteResponseDtoExtended extends NoteResponseDto {
    author: AppUserDTO2Extended;
}

const extendLoanNote = (note: NoteResponseDto): NoteResponseDtoExtended => {
    return {
        ...note,
        author: extendAppUserDTO(note.author),
    }
}

export const loanNoteApi = baseApi.enhanceEndpoints({ addTagTypes: ['NoteResponseDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getLoanNotes: build.query<NoteResponseDtoExtended[], { loanId: string }>({
            query: (data) => ({
                url: `/v2/notes/loan/${data.loanId}`,
                method: 'GET',
            }),
            providesTags: (result) => [
                { type: 'NoteResponseDto' as const, id: 'LIST' },
                ...result.map(({ id }) => ({ type: 'NoteResponseDto' as const, id })),
            ],
            transformResponse: (response: NoteResponseDto[]) => response.map(extendLoanNote),
        }),
        createLoanNote: build.mutation<NoteResponseDto, NoteRequestDto>({
            query: (data) => ({
                url: '/v2/notes',
                method: 'POST',
                data,
            }),
            invalidatesTags: [{ type: 'NoteResponseDto', id: 'LIST' }],
        }),
        deleteLoanNote: build.mutation<NoteResponseDto, { id: string }>({
            query: (data) => ({
                url: `/v2/notes/${data.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) => [{ type: 'NoteResponseDto', id: result.id }],
        }),
        updateLoanNote: build.mutation<NoteResponseDto, NoteRequestDto>({
            query: (data) => ({
                url: `/v2/notes/${data.id}`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: (result) => [{ type: 'NoteResponseDto', id: result.id }],
        }),
        markNoteAsRead: build.mutation<NoteResponseDto, { id: string }>({
            query: (data) => ({
                url: `/v2/notes/${data.id}/read`,
                method: 'POST',
            }),
            invalidatesTags: (result) => [{ type: 'NoteResponseDto', id: result.id }],
        }),
        markNoteAsUnread: build.mutation<NoteResponseDto, { id: string }>({
            query: (data) => ({
                url: `/v2/notes/${data.id}/unread`,
                method: 'POST',
            }),
            invalidatesTags: (result) => [{ type: 'NoteResponseDto', id: result.id }],
        }),
        markStatusFlag: build.mutation<NoteResponseDto, { id: string, status: NoteStatus }>({
            query: (data) => ({
                url: `/v2/notes/${data.id}/status/${data.status}`,
                method: 'POST',
            }),
            invalidatesTags: (result) => [{ type: 'NoteResponseDto', id: result.id }],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetLoanNotesQuery,
    useCreateLoanNoteMutation,
    useDeleteLoanNoteMutation,
    useUpdateLoanNoteMutation,
    useMarkNoteAsReadMutation,
    useMarkNoteAsUnreadMutation,
    useMarkStatusFlagMutation,
} = loanNoteApi;



