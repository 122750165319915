import type { Action, PreloadedState } from '@reduxjs/toolkit';
import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { StateFromReducersMapObject } from "redux";
import type { ThunkAction } from 'redux-thunk';
import listenForEvents from 'src/lib/app-events/listenForEvents';
import notifyEventListeners from 'src/lib/app-events/notifyEventListeners';
import { actionApi } from 'src/services/actionApi';
import { adminApi } from 'src/services/adminApi';
import { anonymousApi } from 'src/services/anonymousApi';
import { appApi } from 'src/services/appApi';
import { authApi } from 'src/services/authApi';
import { baseApi } from 'src/services/baseApi';
import { companyApi } from 'src/services/companyApi';
import { contactApi } from 'src/services/contactApi';
import { documentApi } from 'src/services/documentApi';
import { emailSignatureApi } from 'src/services/emailSignatureApi';
import { entityApi } from 'src/services/entityApi';
import { eventApi } from 'src/services/eventApi';
import { learningCenterApi } from 'src/services/learningCenterApi';
import { lenderShoeBoxApi } from 'src/services/lenderShoeBoxApi';
import { loanApi } from 'src/services/loanApi';
import { loanDetailApi } from 'src/services/loanDetailApi';
import { loanNoteApi } from 'src/services/loanNotesApi';
import { messageApi } from 'src/services/messageApi';
import { noteApi } from 'src/services/noteApi';
import { notificationApi } from 'src/services/notificationApi';
import { packageApi } from 'src/services/packageApi';
import { taskApi } from 'src/services/taskApi';
import { templateApi } from 'src/services/templateApi';
import { userApi } from 'src/services/userApi';
import { reducers, rootReducer } from 'src/store/root-reducer';

const listenerMiddleware = createListenerMiddleware()

const packageApiMutations = [
  'createElements',
  'updateElements',
  'deleteElements',
  'markElementsForDelete',
  'unMarkElementsForDelete',
  'copyElements',
  'addAnswerToElement',
  'deleteAnswerFromElement',
  'createSharedInfoElement',
  'generateElementsZip',
  'deleteElementsZip',
  'deleteSharedInfoElement',
  'createEmptySharepointDocument',
  'copyAnswerToElement',
  'duplicateElements',
  'deleteAnswerFromTrash',
] as const

listenerMiddleware.startListening({
  predicate: (action) => {
    // return true when the listener should run
    const isPackageApiMutationFulfilled = packageApiMutations.some(mutation =>
      packageApi.endpoints[mutation].matchFulfilled(action)
    );

    if (isPackageApiMutationFulfilled) {
      return true;
    }
    return false;
  },
  effect: () => {
    notifyEventListeners('PACKAGE_UPDATED')
  }
})



export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      adminApi.middleware,
      companyApi.middleware,
      learningCenterApi.middleware,
      lenderShoeBoxApi.middleware,
      anonymousApi.middleware,
      appApi.middleware,
      userApi.middleware,
      documentApi.middleware,
      baseApi.middleware,
      loanApi.middleware,
      authApi.middleware,
      entityApi.middleware,
      messageApi.middleware,
      packageApi.middleware,
      taskApi.middleware,
      noteApi.middleware,
      actionApi.middleware,
      contactApi.middleware,
      notificationApi.middleware,
      eventApi.middleware,
      loanDetailApi.middleware,
      templateApi.middleware,
      emailSignatureApi.middleware,
      loanNoteApi.middleware,
      listenerMiddleware.middleware
    ),
});

function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: [
            'formElement/setZipPasswordCallback',
            'ui/setDadZipFileHandlingFiles',
            'ui/setZipFileActionCallback',
            'ui/setDadZipFileHandling'
          ],
          // Ignore these paths in the state
          ignoredPaths: ['formElement.zipPasswordCallback', 'ui.zipFileActionCallback'],
        },
      }),
  })
}

listenForEvents('PACKAGE_UPDATED', () => {
  store.dispatch(packageApi.util.invalidateTags([
    'FormElementsV2ResponseDto',
    { type: 'ConsolidatedTasksDto', id: "LIST" },
    'BasicLoanDto',
    { type: 'FormElementsV2ResponseDto', id: "LIST" },
    { type: 'LoanDto', id: "LIST" }
  ]));
})
export type RootState = StateFromReducersMapObject<typeof reducers>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<T = any> = ThunkAction<T, RootState, null, Action<string>>;

export type AppThunkPromise<T = any> = ThunkAction<Promise<T>, RootState, null, Action<string>>;

export type AppStore = ReturnType<typeof setupStore>

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
