

import { BellFilterStatus, BellNotificationUnreadDto, NotificationBellDto } from 'src/backend';
import { AppUserDTO2Extended } from 'src/types/user';
import { extendAppUserDTO } from 'src/utils/user/extend-app-user-dto';

import { baseApi } from './baseApi';

export interface ExtendedNotificationBellDto extends NotificationBellDto {
    user: AppUserDTO2Extended;
}

export const notificationApi = baseApi.enhanceEndpoints({ addTagTypes: ['NotificationBellDto', 'BellNotificationUnreadDto'] }).injectEndpoints({
    endpoints: (build) => ({
        markAllNotificationsAsRead: build.mutation<void, { notificationId: string }>({
            query: (data) => ({
                url: `/v2/notification/bell/${data.notificationId}/markReadAll`,
                method: 'POST',
            }),
            invalidatesTags: ['NotificationBellDto', 'BellNotificationUnreadDto'],
        }),
        markNotificationAsRead: build.mutation<void, { notificationId: string }>({
            query: (data) => ({
                url: `/v2/notification/bell/${data.notificationId}/markRead`,
                method: 'POST',
            }),
            invalidatesTags: ['NotificationBellDto', 'BellNotificationUnreadDto'],
        }),
        getNotifications: build.query<ExtendedNotificationBellDto[], { status: BellFilterStatus; assigned: boolean; page?: number; size?: number; }>({
            query: (data) => ({
                url: '/v2/notification/bell',
                method: 'GET',
                data
            }),
            providesTags: (result) => [
                'NotificationBellDto',
                ...(result ?? []).map(({ id }) => ({ type: 'NotificationBellDto' as const, id }))
            ],
            transformResponse: (response: NotificationBellDto[]) => response.map((notification) => ({
                ...notification,
                user: extendAppUserDTO(notification.user),
            })),
        }),
        getBellNotificationsUnreadCount: build.query<BellNotificationUnreadDto, void>({
            query: () => ({
                url: '/v2/notification/bell/unread/count',
                method: 'GET',
            }),
            providesTags: ['BellNotificationUnreadDto'],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetNotificationsQuery,
    useLazyGetNotificationsQuery,
    useMarkAllNotificationsAsReadMutation,
    useMarkNotificationAsReadMutation,
    useGetBellNotificationsUnreadCountQuery
} = notificationApi;



