import { createSelector, createSlice } from '@reduxjs/toolkit';
import { LoanOverviewView, UNAUTHORIZED_ERROR_STATUS } from 'src/constants/ui';
import type { AppThunk, RootState } from 'src/store';
import { TLoanOverviewView } from 'src/types/ui';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';

interface UiState {
    previewMode?: boolean,
    connectionError?: boolean,
    closeChatAfterAction?: boolean,
    useAlternativeFilePreviewer?: boolean,
    navbarTitle: string,
    drawerOpen: boolean;
    formElementsSortingEnabled: boolean;
    formElementNavigator: null;
    formElementTreeMapDepth: number;
    hiddenPhases: string[];
    formElementTreeMapHideFiles: boolean;
    lgUpDrawerOpen: boolean;
    messagesExpanded: boolean;
    activeOverviewView: TLoanOverviewView;
    agreedToTOS: boolean;
    keyPreferences: Record<string, any>;
    sendEmailAddRemoveUser: boolean,
    sendEmailAppMessage: boolean,
    sendEmailLoanStatus: boolean,
    sendTextAddRemoveUser: boolean,
    sendTextAppMessage: boolean,
    sendTextLoanStatus: boolean,
}

const initialState: UiState = {
    previewMode: false,
    connectionError: false,
    closeChatAfterAction: false,
    useAlternativeFilePreviewer: false,
    hiddenPhases: [],
    navbarTitle: '',
    formElementsSortingEnabled: true,
    drawerOpen: undefined,
    formElementNavigator: null,
    formElementTreeMapDepth: 2,
    formElementTreeMapHideFiles: false,
    lgUpDrawerOpen: false,
    messagesExpanded: false,
    activeOverviewView: LoanOverviewView.Tasks,
    agreedToTOS: false,
    keyPreferences: {},
    sendEmailAddRemoveUser: true,
    sendEmailAppMessage: true,
    sendEmailLoanStatus: true,
    sendTextAddRemoveUser: true,
    sendTextAppMessage: true,
    sendTextLoanStatus: true,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setIsPreviewMode(state: UiState, action: { payload: boolean }): void {
            state.previewMode = action.payload;
        },
        toggleConnectionError(state: UiState, action: { payload: boolean }): void {
            state.connectionError = action.payload;
        },
        setCloseChatAfterAction(state: UiState, action: { payload: boolean }): void {
            state.closeChatAfterAction = action.payload;
        },
        setUseAlternativeFilePreviewer(state: UiState, action: { payload: boolean }): void {
            state.useAlternativeFilePreviewer = action.payload;
        },
        setNavbarTitle(state: UiState, action: { payload: string }): void {
            state.navbarTitle = action.payload;
        },
        setFormElementsSortingEnabled(state: UiState, action: { payload: boolean }): void {
            state.formElementsSortingEnabled = action.payload;
        },
        setDrawerOpen(state: UiState, action: { payload: boolean }): void {
            state.drawerOpen = action.payload;
        },
        setFormElementTreeMapDepth(state: UiState, action: { payload: number }): void {
            state.formElementTreeMapDepth = action.payload;
        },
        setFormElementTreeMapHideFiles(state: UiState, action: { payload: boolean }): void {
            state.formElementTreeMapHideFiles = action.payload;
        },
        openDrawerLgAndUp(state: UiState): void {
            state.lgUpDrawerOpen = true;
            state.drawerOpen = true;
        },
        setHiddenPhases(state: UiState, action: { payload: string[] }): void {
            state.hiddenPhases = action.payload;
        },
        setActiveOverviewView(state: UiState, action: { payload: TLoanOverviewView }): void {
            state.activeOverviewView = action.payload;
        },
        setAgreedToTOS(state: UiState, action: { payload: boolean }): void {
            state.agreedToTOS = action.payload;
        },
        setKeyPreference(state: UiState, action: { payload: { key: string, value: any } }): void {
            state.keyPreferences[action.payload.key] = action.payload.value;
        },
        setSendEmailAddRemoveUser(state: UiState, action: { payload: boolean }): void {
            state.sendEmailAddRemoveUser = action.payload;
        },
        setSendEmailAppMessage(state: UiState, action: { payload: boolean }): void {
            state.sendEmailAppMessage = action.payload;
        },
        setSendEmailLoanStatus(state: UiState, action: { payload: boolean }): void {
            state.sendEmailLoanStatus = action.payload;
        },
        setSendTextAddRemoveUser(state: UiState, action: { payload: boolean }): void {
            state.sendTextAddRemoveUser = action.payload;
        },
        setSendTextAppMessage(state: UiState, action: { payload: boolean }): void {
            state.sendTextAppMessage = action.payload;
        },
        setSendTextLoanStatus(state: UiState, action: { payload: boolean }): void {
            state.sendTextLoanStatus = action.payload;
        }
    }
});

export const { reducer, actions: uiActions } = uiSlice;

export const toggleDrawer = (open: boolean): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setDrawerOpen(open));
}

export const openDrawerLgAndUp = (): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.openDrawerLgAndUp());
}


export const setActiveOverviewView = (view: TLoanOverviewView): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setActiveOverviewView(view));
}

export const setNavbarTitle = (title: string): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setNavbarTitle(title));
};

export const handleRequestError = (error: any) => (dispatch, getState) => {
    const { view: { loggedInUserId } } = getState();
    if (error?.status >= 500 || error?.message === 'Failed to fetch') {
        dispatch(uiSlice.actions.toggleConnectionError(true));
    } else if ([UNAUTHORIZED_ERROR_STATUS, 403].includes(error?.status) && !!loggedInUserId) {
        console.error('request error, reloading page', error)
        document.location.reload();
        notifyBugTracker(error);
    } else {
        dispatch(uiSlice.actions.toggleConnectionError(false));
    }
};

export const uiSelector = (state: RootState) => state.ui
export const selectDrawerOpen = (state: RootState) => state.ui.drawerOpen
export const selectConnectionError = (state: RootState) => state.ui.connectionError

export const hiddenPhasesSelector = createSelector([
    (state: RootState) => state.ui
], (ui) => ui.hiddenPhases);


export default uiSlice;
