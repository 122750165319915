import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Tooltip from "components/dist/atoms/Tooltip";
import dynamic from 'next/dynamic';
import React from 'react';
import { AppErrorBoundary } from 'src/components/app-error-boundary';

import { createTheme } from '../theme';
import { ReduxProvider } from './redux-provider';

const ReactToastContainer = dynamic(() => import('src/components/react-toast-container.component').then(mod => mod.ReactToastContainer), { ssr: false });
const AuthProvider = dynamic(() => import('src/contexts/auth-context').then(mod => mod.AuthProvider), { ssr: false });

// load route-change-progress component dynamically
const RouteChangeProgress = dynamic(() => import('src/components/route-change-progress.component').then(mod => mod.RouteChangeProgress), { ssr: false });
Bugsnag.start({
  apiKey: 'c55395ad1b6eb693f246d8a73af4251d',
  plugins: [new BugsnagPluginReact()],
})


const theme = createTheme();

// Create the error boundary...
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export const AppProvider = ({ children }) => {

  return (<Tooltip.Provider>
    <ReduxProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary FallbackComponent={AppErrorBoundary}>
            {children}
            <RouteChangeProgress />
            <ReactToastContainer />
          </ErrorBoundary>
        </ThemeProvider>
      </AuthProvider>
    </ReduxProvider>
  </Tooltip.Provider>)
}