import { isProductionDomain } from "src/utils/is-production-domain";

const debugMessage = (message: string, debug: boolean | undefined) => {
    const isProduction = isProductionDomain();

    if (isProduction || !debug) {
        return;
    }

    console.log(message);
};

export default debugMessage;